<template>
  <Dialog v-if="show" @close="confirmStore.cancel()">
    <template #title>{{ title }}</template>
    <template #body>
      <div class="confirm-body">
        <div class="confirm-content" v-html="message"></div>
        <div class="confirm-btns">
          <button v-if="confirmStore.showCancel" class="dialog-btn2" @click="confirmStore.cancel()">
            {{ $t('cancel') }}
          </button>
          <button class="dialog-btn1" @click="confirmStore.confirm()">
            {{ $t('confirm') }}
          </button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { useConfirmStore } from '@/stores/confirm.js'
import { storeToRefs } from 'pinia'
import Dialog from '@/components/Dialog/Dialog.vue'

const { title, message, show } = storeToRefs(useConfirmStore())
const confirmStore = useConfirmStore()
</script>

<style scoped lang="less">
.confirm-body {
  .confirm-content {
    padding: 10 * @pcx;
    overflow: auto;
  }

  .confirm-btns {
    display: flex;
    align-items: center;

    button {
      &:nth-child(2) {
        margin-left: 20 * @pcx;
      }
    }
  }
}
</style>
