import { createApp } from 'vue'
import 'animate.css/animate.min.css'
import './style.css'
import App from './App.vue'

import en from './locales/en.js'
import lk from './locales/lk.js'

import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import router from '@/router/index.js'
import { clickOutside } from '@/directive/index.js'
import * as Sentry from '@sentry/vue'

const messages = {
  en,
  lk,
}

const i18n = createI18n({
  legacy: false,
  locale: 'lk',
  messages,
})

const pinia = createPinia()
pinia.use(createPersistedState())
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(i18n)

app.directive('click-outside', {
  mounted(el, binding) {
    clickOutside(el, binding)
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
})

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://001e0344786b0937c111e60b29109b75@sentry.link-api.com/7',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        instrumenter: (args) => {
          // 如果是 OPTIONS 请求，不添加追踪头部
          console.log(args.request)
          if (args.request?.method === 'OPTIONS') {
            return false
          }
          console.warn(args.request)

          const url = args.request?.url
          if (!url) return false

          // 检查是否为同域名请求
          if (!url.startsWith(location.origin)) {
            return false
          }

          // 检查是否为静态资源
          if (
            /\.(js|mjs|jsx|ts|tsx|css|less|scss|sass|styl|png|jpg|jpeg|gif|svg|ico|woff|woff2|ttf|eot|otf|mp4|webm|ogg|mp3|wav|pdf|doc|docx|xls|xlsx|ppt|pptx|zip|rar|tar|gz|map)$/i.test(
              url,
            )
          ) {
            return false
          }

          return true
        },
      }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    // 只追踪同域名请求
    tracePropagationTargets: [location.origin],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

app.mount('#app')

// if (window.innerWidth <= 768) {
//   window.location.href = ''
// }

export { i18n }
