<template>
  <Dialog v-if="showLoginDialog" @close="showLoginDialog = false">
    <template #title>
      {{ $t('login') }}
    </template>

    <template #body>
      <div class="logo">
        <img src="@/assets/img/logo/luckcoinz.webp" alt="" />
      </div>

      <div class="form-item">
        <img src="@/assets/img/v2/username.webp" alt="" />
        <div class="form-input">
          <input
            v-model="username"
            @focusin="focusUsername = true"
            @focusout="focusUsername = false"
            name="username"
            type="text"
            :placeholder="$t('loginUserNamePlaceholder')"
            class="login-input"
            @keyup.enter="login()" />
        </div>
      </div>
      <div class="form-item">
        <img src="@/assets/img/v2/password.webp" alt="" />
        <div class="form-input">
          <div class="flex-grow">
            <input
              v-model="password"
              @focusin="focusPassword = true"
              @focusout="focusPassword = false"
              name="password"
              :type="showPassword ? '' : 'password'"
              :placeholder="$t('loginPasswordPlaceholder')"
              class="login-input"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @keyup.enter="login()" />
          </div>
          <div @click="showPassword = !showPassword" class="pw-btn">
            <svg v-if="!showPassword" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
            </svg>
            <svg v-else focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
            </svg>
          </div>
        </div>
      </div>

      <button class="dialog-btn1" @click="login" :disabled="!username || !password">
        <img v-if="loginLoading" src="@/assets/img/loading.webp" alt="" class="loading" />
        <span v-else>{{ $t('login') }}</span>
      </button>
      <button class="dialog-btn2" @click="goView('/register')">
        {{ $t('register') }}
      </button>
    </template>
  </Dialog>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { nextTick, ref } from 'vue'
import { useUserStore } from '@/stores/user.js'
import { storeToRefs } from 'pinia'
import { useConfigStore } from '@/stores/config.js'
import Dialog from '@/components/Dialog/Dialog.vue'
import { generateFingerprint } from '@/utils/utils.js'

const router = useRouter()
const goView = (path) => {
  showLoginDialog.value = false

  router.push(path)
}
const username = ref('')
const password = ref('')

const userStore = useUserStore()
const { showLoginDialog } = storeToRefs(useConfigStore())

const loginLoading = ref(false)
const login = async () => {
  if (!username.value || !password.value) {
    return
  }

  if (loginLoading.value) {
    return
  }
  loginLoading.value = true
  userStore
    .login({
      account: username.value,
      password: password.value,
      platform: 'PC',
      fingerprint: await generateFingerprint(),
      domain: window.location.hostname,
    })
    .then(() => {
      // goView('/')
      showLoginDialog.value = false
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      loginLoading.value = false
    })
}
const showPassword = ref(false)
const focusUsername = ref(false)
const focusPassword = ref(false)
</script>

<style scoped lang="less">
/deep/ .dialog-main {
  width: 500 * @pcx;
}

.logo {
  width: 100%;
  padding-top: 7 * @pcx;

  img {
    width: 260 * @pcx;
    margin: auto;
  }
}

.form-item {
  margin-top: 17 * @pcx;
  width: 100%;
  display: flex;
  align-items: center;
  height: 48 * @pcx;
  background: #222833;
  border-radius: 10 * @pcx;
  border: 1 * @pcx solid #51586b;
  padding: 10 * @pcx;

  img {
    width: 28 * @pcx;
    height: 28 * @pcx;
  }

  .form-input {
    flex: 1;
    border-left: 1 * @pcx solid #8f94a3;
    margin-left: 10 * @pcx;
    padding-left: 10 * @pcx;
    display: flex;
    align-items: center;
    height: 24 * @pcx;

    input {
      background: transparent;
      width: 100%;
      height: 100%;
      font-size: 15 * @pcx;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        -webkit-transition:
          color 9999s ease-out,
          background-color 9999s ease-out;
      }
    }

    .pw-btn {
      margin-left: 10 * @pcx;

      svg {
        fill: rgba(255, 255, 255, 0.3);
        width: 24 * @pcx;
        height: 24 * @pcx;
      }
    }
  }
}

.dialog-btn1 {
  margin: 17 * @pcx 0;

  .loading {
    width: 40 * @pcx;
  }
}
</style>
