<template>
  <!--  @click="$emit('close')"-->
  <div class="dialog">
    <div @click.stop class="dialog-main">
      <div class="dialog-head">
        <div class="title">
          <slot name="title"></slot>
        </div>

        <svg
          @click="$emit('close')"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          style="color: rgb(159, 162, 171); cursor: pointer">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        </svg>
      </div>

      <div class="dialog-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'

onMounted(() => {
  document.body.style.overflow = 'hidden'
})

onBeforeUnmount(() => {
  document.body.style.overflow = 'auto'
})
</script>

<style scoped lang="less">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10 * @pcx);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: #ffffff;

  &-main {
    background: #373942;
    max-width: 80%;
    max-height: 100%;
    overflow: hidden;
    border-radius: 10 * @pcx;
    transform: translateY(0);
    animation: kf-dialog 0.3s ease-in-out;
    display: flex;
    flex-direction: column;

    @keyframes kf-dialog {
      0% {
        transform: translateY(100vh);
      }

      100% {
        transform: translateY(0);
      }
    }
  }

  &-head {
    flex: 0 0 auto;
    width: 100%;
    height: 55 * @pcx;
    background: #343640;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20 * @pcx;

    .title {
      flex: 1;
      font-family: Roboto, Roboto, sans-serif;
      font-weight: bold;
      font-size: 18 * @pcx;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    svg {
      flex: 0 0 auto;
      fill: #585a61;
      width: 32 * @pcx;
      height: 32 * @pcx;
    }
  }

  &-body {
    flex: 1;
    overflow: auto;
    background: #191e28;
    padding: 15 * @pcx;
  }

  /deep/ &-btn1 {
    width: 100%;
    height: 48 * @pcx;
    background: linear-gradient(90deg, #6559ed 0%, #b16fe3 52%, #f82e2e 100%);
    border-radius: 10 * @pcx;
    font-family: Roboto, Roboto, sans-serif;
    font-weight: bold;
    font-size: 17 * @pcx;
    color: #ffffff;
    line-height: 20 * @pcx;
    text-align: center;
    font-style: normal;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &[disabled] {
      background: #555;
      color: #aaa;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  /deep/ &-btn2 {
    width: 100%;
    height: 48 * @pcx;
    background: #474e5a;
    border-radius: 10 * @pcx;
    font-family: Roboto, Roboto, sans-serif;
    font-weight: bold;
    font-size: 17 * @pcx;
    color: #ffffff;
    line-height: 20 * @pcx;
    text-align: center;
    font-style: normal;
    text-transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
