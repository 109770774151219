import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore(
  'config',
  () => {
    const language = ref('lk')
    const splashPreventDate = ref('')
    const hasMessage = ref(false)
    const showLoginDialog = ref(false)

    return {
      language,
      splashPreventDate,
      hasMessage,
      showLoginDialog,
    }
  },
  {
    persist: true,
  }
)
