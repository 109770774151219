export default {
  myBank: 'මගේ බැංකු',
  titleRequestBank: 'තොරතුරු සත්‍යාපනය',
  submitDepositBtn: 'ඇණවුම ඉදිරිපත් කරන්න',
  rechargeToBankTitle: 'ඉලක්ක බැංකු තොරතුරු',
  rechargeToBankTip:
    'ඇණවුම ඉදිරිපත් කිරීමෙන් පසු එය මිනිත්තු 60ක් සඳහා වලංගු වේ. මිනිත්තු 60 ඉක්මවා ගියහොත්, ඇණවුම ස්වයංක්‍රීයව අවලංගු වන අතර නව අයදුම්පතක් අවශ්‍ය වනු ඇත.',
  requestFail: 'ඉල්ලීම අසාර්ථක විය, කරුණාකර පසුව නැවත උත්සාහ කරන්න',
  btiSports: 'BTIසම්ප්‍රාදේශ',
  bt1: 'BT1',
  betConstruct: 'බෙට්කන්ස්ට්‍රක්ට්',
  liveCasino: 'ජීව් කැසීනෝ',
  slot: 'ස්ලොට්',
  liveHoldEm: 'ජීව් හෝල්ඩම්',
  promos: 'ප්‍රමෝ',
  promotions: 'ප්‍රමෝ',
  vip: 'VIP',
  rules: 'නීති',
  topWinners7: 'පසුගිය 7 ජයග්‍රාහකයින්',
  topWinners24: 'පැය 24 ජයග්‍රාහකයින්',
  playerId: 'ක්‍රීඩක ID',
  multi: 'බහු',
  odds: 'අනුපාත',
  betAmount: 'ඔට්ටු මුදල',
  winAmount: 'ජය මුදල',
  title: 'මාතෘකාව',
  titlepop: 'තහවුරු කිරීම',
  depositContentTip: 'තැන්පතු විස්තර',
  date: 'දිනය',
  action: 'ක්‍රියාව',
  deposit: 'තැන්පතුව',
  withdrawal: 'ආපසු ගැනීම',
  coupon: 'කූපන්',
  coupon2: 'කූපන්',
  records: 'වාර්තා',
  roling: 'රෝලින්',
  profile: 'පැතිකඩ',
  notices: 'දැනුම්දීම්',
  dontShowToday: 'අද නොපෙන්වන්න',
  closeAll: 'සියල්ල වසන්න',
  accountDetails: 'ගිණුම් විස්තර',
  bankDetails: 'බැංකු විස්තර',
  changePassword: 'මුරපදය වෙනස් කරන්න',
  account: 'ගිණුම',
  name: 'නම',
  dateRegistered: 'ලියාපදිංචි දිනය',
  contactNumber: 'දුරකථන අංකය',
  update: 'යාවත්කාලීන',
  bankName: 'බැංකුව',
  accountName: 'ගිණුම් නම',
  accountNumber: 'ගිණුම් අංකය',
  verifyOldPassword: 'පැරණි මුරපදය',
  enterNewPassword: 'නව මුරපදය',
  reEnterNewPassword: 'නව මුරපදය නැවත',
  fieldRequired: 'අවශ්‍යයි',
  myAccountDetails: 'මගේ ගිණුම් විස්තර',
  passwordsDoNotMatch: 'මුරපද නොගැලපේ',
  changePasswordSuccess: 'මුරපදය වෙනස් කළා',
  save: 'සුරකින්න',
  logout: 'ඉවත් වන්න',
  amountRangeTip: 'මුදල 1,000 - 1,000,000 අතර විය යුතුය',
  amountRangeTip2: 'මුදල 1,000 - 1,000,000 අතර විය යුතුය',
  balanceNotEnough: 'ශේෂය මදි',
  depositAmountError: 'තැන්පතු මුදල වැරදියි',
  depositAlreadyExist: 'දැනටමත් තැන්පත් කර ඇත',
  promotionNotExist: 'ප්‍රවර්ධනය නොමැත',
  depositOrderNotExist: 'තැන්පතු ඇණවුම නොමැත',
  depositOrderNotPending: 'තැන්පතු ඇණවුම අපේක්ෂිත නොවේ',
  withdrawExist: 'ආපසු ගැනීම පවතී',
  withdrawAmountError: 'ආපසු ගැනීමේ මුදල වැරදියි',
  withdrawOrderNotExist: 'ආපසු ගැනීමේ ඇණවුම නොමැත',
  withdrawOrderNotPending: 'ආපසු ගැනීමේ ඇණවුම අපේක්ෂිත නොවේ',
  today: 'අද',
  yesterday: 'ඊයේ',
  last3days: 'පසුගිය දින 3',
  last7days: 'පසුගිය දින 7',
  last30days: 'පසුගිය දින 30',
  amountReceived: 'ලැබුණු මුදල',
  reset: 'යළි පිහිටුවන්න',
  search: 'සොයන්න',
  transactionID: 'ගනුදෙනු ID',
  bonus: 'බෝනස්',
  amount: 'මුදල',
  status: 'තත්ත්වය',
  noResultsFound: 'ප්‍රතිඵල නැත',
  loadingData: 'දත්ත පූරණය වෙමින්',
  passwordTip: 'මුරපදය අක්ෂර 6කට වැඩි විය යුතුය',
  verifyPasswordTip: 'මුරපද තහවුරු කරන්න',
  sendCodeTip: 'තත්පර {seconds}කින් යවන්න',
  phoneTip: 'වලංගු දුරකථන අංකයක් ඇතුළත් කරන්න',
  agentOrUserNotExistTip: 'නියෝජිත හෝ පරිශීලක නොමැත',
  createAnAccount: 'ගිණුමක් සාදන්න',
  personalInfo: 'පුද්ගලික තොරතුරු',
  bankAccountName: 'බැංකු ගිණුම් නම',
  bank: 'බැංකුව',
  contactEmailTip: 'සම්බන්ධතා විද්‍යුත් තැපෑල',
  mobileNo: 'ජංගම අංකය',
  mobileNumberFormatTip: 'ජංගම අංකය ආකෘතිය',
  verifyCode: 'තහවුරු කේතය',
  referredBy: 'යොමු කළේ',
  agentCode: 'නියෝජිත කේතය',
  register: 'ලියාපදිංචි වන්න',
  termsAndConditions: 'නියම සහ කොන්දේසි',
  totalBalance: 'මුළු ශේෂය',
  withdrawable: 'ආපසු ගත හැකි',
  rebate: 'වට්ටම',
  sportBonus: 'ක්‍රීඩා බෝනස්',
  liveBonus: 'සජීවී බෝනස්',
  transfer: 'මාරු කරන්න',
  transferTip: 'මාරු කිරීමේ උපදෙස්',
  from: 'සිට',
  to: 'දක්වා',
  wallet: 'මුදල් පසුම්බිය',
  all: 'සියල්ල',
  sport: 'ක්‍රීඩා',
  liveSlots: 'සජීවී සහ ස්ලොට්',
  promotion: 'ප්‍රවර්ධන',
  type: 'වර්ගය',
  percentage: 'ප්‍රතිශතය',
  rolingType: 'රෝලින් වර්ගය',
  bonusType: 'බෝනස් වර්ගය',
  promotionDetail: 'ප්‍රවර්ධන විස්තර',
  currentProgress: 'වත්මන් ප්‍රගතිය',
  remaining: 'ඉතිරි',
  operation: 'මෙහෙයුම',
  days: 'දින',
  infinite: 'අසීමිත',
  inProgress: 'ක්‍රියාත්මක වෙමින්',
  cancel: 'අවලංගු',
  directReferral: 'සෘජු යොමුව',
  indirectReferral: 'වක්‍ර යොමුව',
  gameHistory: 'ක්‍රීඩා ඉතිහාසය',
  result: 'ප්‍රතිඵලය',
  withdrawForm: {
    amount: 'මුදල',
    amountReceived: 'ලැබුණු මුදල',
    bankName: 'බැංකුව',
    accountName: 'ගිණුම් නම',
    accountNumber: 'ගිණුම් අංකය',
  },
  history: 'ඉතිහාසය',
  mine: 'මගේ',
  view: 'බලන්න',
  rebateAmount: 'වට්ටම් මුදල',
  min: 'අවම',
  max: 'උපරිම',
  submit: 'යොමු කරන්න',
  noBonus: 'බෝනස් නැත',
  login: 'පිවිසෙන්න',
  members: 'සාමාජිකයින්',
  loginTip: 'පිවිසුම් උපදෙස්',
  sendCode: 'කේතය යවන්න',
  tabbar: {
    home: 'මුල් පිටුව',
    support: 'සහාය',
    promos: 'ප්‍රවර්ධන',
    wallet: 'මුදල් පසුම්බිය',
    myAccount: 'මගේ ගිණුම',
  },
  details: 'විස්තර',
  ResponsibleGaming: 'වගකිවයුතු ක්‍රීඩාව',
  PrivacyPolicy: 'රහස්‍යතා ප්‍රතිපත්තිය',
  ContactUs: 'අප අමතන්න',
  QuickLinks: 'ක්ෂණික සබැඳි',
  Support: 'සහාය',
  License: 'බලපත්‍රය',
  Providers: 'සැපයුම්කරුවන්',
  bronze: 'ලෝකඩ',
  silvery: 'රිදී',
  golden: 'රන්',
  diamond: 'දියමන්ති',
  rebates: 'වට්ටම්',
  weeklyCache: 'සතිපතා කෑෂ්',
  depositBonus: 'තැන්පතු බෝනස්',
  VIPLevelConditions: 'VIP මට්ටම් කොන්දේසි',
  VIPLevelDiscounts: 'VIP මට්ටම් වට්ටම්',
  VIPUpgradeRequirements: 'VIP උසස් කිරීමේ අවශ්‍යතා',
  TermsOfUse: 'භාවිත කොන්දේසි',
  noEvents: 'සිදුවීම් නැත',
  sports: 'ක්‍රීඩා',
  slots: 'ස්ලොට්',
  accMenuMessage: 'පණිවිඩ',
  transactionHistory: 'ගනුදෙනු ඉතිහාසය',
  bonusCouponCash: 'බෝනස්/කූපන්/මුදල්',
  languageSettings: 'භාෂා සැකසුම්',
  helpCenter: 'උපකාර මධ්‍යස්ථානය',
  support: {
    head: 'සහාය',
    title: 'උදව් අවශ්‍යද?',
    contactUs: 'අප අමතන්න',
    sendMessage: 'පණිවිඩයක් යවන්න',
    faq: 'නිති අසන පැන',
    frequentlyAskedQuestions: 'නිතර අසන ප්‍රශ්න',
    telegram: 'ටෙලිග්‍රෑම්',
    preparing: 'සූදානම් වෙමින්',
  },
  topPicks: 'හොඳම තේරීම්',
  allLiveCasinoGames: 'සියලු සජීවී කැසිනෝ ක්‍රීඩා',
  new: 'නව',
  hot: 'ජනප්‍රිය',
  playNow: 'දැන් ක්‍රීඩා කරන්න',
  SEARCHFORGAMES: 'ක්‍රීඩා සොයන්න',
  RebateWalletBalance: 'වට්ටම් පසුම්බි ශේෂය',
  Options: 'විකල්ප',
  rebateTip: 'වට්ටම් උපදෙස්',
  noPromotionTip: 'ප්‍රවර්ධන නැත',
  wagering: 'ඔට්ටු ඇල්ලීම',
  minimumDeposit: 'අවම තැන්පතුව',
  minimumOdds: 'අවම අනුපාත',
  minimumParlay: 'අවම පාර්ලේ',
  effectiveDays: 'වලංගු දින',
  maximumPayoutAmount: 'උපරිම ගෙවීම් මුදල',
  gameFail: 'ක්‍රීඩාව අසාර්ථකයි',
  rolling: 'රෝලින්',
  referrer: 'යොමු කරන්නා',
  depositAmount: 'තැන්පතු මුදල',
  rewardAmount: 'ත්‍යාග මුදල',
  gameType: 'ක්‍රීඩා වර්ගය',
  pending: 'අපේක්ෂිත',
  success: 'සාර්ථකයි',
  failed: 'අසාර්ථකයි',
  canceled: 'අවලංගු කළා',
  timeout: 'කාලය ඉකුත් වී ඇත',
  rollingType: 'රෝලින් වර්ගය',
  couponID: 'කූපන් ID',
  validityPeriod: 'වලංගු කාලය',
  useTime: 'භාවිත කාලය',
  providers: 'සැපයුම්කරුවන්',
  event: 'සිදුවීම',
  rollingCondition: 'රෝලින් කොන්දේසිය',
  select: 'තෝරන්න',
  refuse: 'ප්‍රතික්ෂේප කරන්න',
  expired: 'කල් ඉකුත් වී ඇත',
  unused: 'භාවිතා නොකළ',
  used: 'භාවිතා කළ',
  recycled: 'ප්‍රතිචක්‍රීකරණය කළ',
  username: 'පරිශීලක නාමය',
  password: 'මුරපදය',
  poker: 'පෝකර්',
  referrerHierarchy: 'යොමු කරන්නන්ගේ ධුරාවලිය',
  rewardCycle: 'ත්‍යාග චක්‍රය',
  totalBet: 'මුළු ඔට්ටුව',
  rebatePercent: 'වට්ටම් ප්‍රතිශතය',
  referrerLink: 'යොමු සබැඳිය',
  copy: 'පිටපත් කරන්න',
  copied: 'පිටපත් කළා',
  currentMonthInvitationCapacity: 'මෙම මාසයේ ආරාධනා ධාරිතාව',
  totalInvited: 'මුළු ආරාධිතයින්',
  withdrawableTip: 'ආපසු ගැනීමේ උපදෙස්',
  yuan: 'KRW',
  expireTime: 'කල් ඉකුත්වන වේලාව',
  useWithdrawable: 'ආපසු ගැනීම භාවිතා කරන්න',
  remainingValidDays: 'ඉතිරි වලංගු දින',
  monetaryRewards: 'මුදල් ත්‍යාග',
  cashback: 'මුදල් ආපසු',
  minTransferAmount: 'අවම මාරු මුදල',
  PopularGames: 'ජනප්‍රිය ක්‍රීඩා',
  Shortcuts: 'කෙටිමං',
  RealTimeRedemptionStatus: 'තත්කාලීන මුදල් ආපසු ගැනීමේ තත්ත්වය',
  sendCodeVerify: 'කේතය යවා තහවුරු කරන්න',
  confirm: 'තහවුරු කරන්න',
  sending: 'යවමින්',
  aboutUs: 'අප ගැන',
  use: 'භාවිතා කරන්න',
  currentLinks: 'වත්මන් සබැඳි',
  todayLinks: 'අද සබැඳි',
  CompanyIntroduction: 'සමාගම් හැඳින්වීම',
  GameResponsibility: 'ක්‍රීඩා වගකීම',
  product: 'නිෂ්පාදනය',
  LiveSports: 'සජීවී ක්‍රීඩා',
  OnSiteCasino: 'ස්ථානීය කැසිනෝ',
  Statistics: 'සංඛ්‍යාලේඛන',
  KoreanView: 'කොරියානු දසුන',
  CompetitionResults: 'තරඟ ප්‍රතිඵල',
  BusinessAnalysis: 'ව්‍යාපාර විශ්ලේෂණය',
  help: 'උදව්',
  SportsBettingRegulations: 'ක්‍රීඩා ඔට්ටු ඇල්ලීමේ රෙගුලාසි',
  LiveChatShortcuts: 'සජීවී කතාබස් කෙටිමං',
  FAQ: 'නිති අසන පැන',
  loginUserNamePlaceholder: 'පරිශීලක නාමය ඇතුළත් කරන්න',
  loginUserNameExist: 'පරිශීලක නාමය දැනටමත් පවතී',
  loginPasswordPlaceholder: 'මුරපදය ඇතුළත් කරන්න',
  ChargingDiscounts: 'අය කිරීමේ වට්ටම්',
  LearnMore: 'තව දැනගන්න',
  content: 'අන්තර්ගතය',
  ChargingSuccessful: 'අය කිරීම සාර්ථකයි',
  user: 'පරිශීලක',
  apply: 'අයදුම් කරන්න',
  description: 'විස්තරය',
  PhoneNumber: 'දුරකථන අංකය',
  activity: 'ක්‍රියාකාරකම',
  ClaimCoupons: 'කූපන් ඉල්ලන්න',
  day: 'දිනය',
  week: 'සතිය',
  month: 'මාසය',
  page: 'පිටුව',
  previous: 'පෙර',
  next: 'ඊළඟ',
  personalInformation: 'පුද්ගලික තොරතුරු',
  ChangePassword: 'මුරපදය වෙනස් කරන්න',
  BankAccountInformation: 'බැංකු ගිණුම් තොරතුරු',
  Inbox: 'ලැබුණු පණිවිඩ',
  delete: 'මකන්න',
  knockout: 'නොක්අවුට්',
  introduce: 'හඳුන්වා දෙන්න',
  target: 'ඉලක්කය',
  CompleteInformation: 'තොරතුරු සම්පූර්ණ කරන්න',
  verification: 'සත්‍යාපනය',
  registerView: {
    SelectChatAPP: 'චැට් ඇප් තෝරන්න',
    SelectCountry: 'රට තෝරන්න',
    ID: 'හැඳුනුම්පත',
    accountPlaceholder: 'ගිණුම් නාමය ඇතුළත් කරන්න',
    passwordPlaceholder: 'මුරපදය ඇතුළත් කරන්න',
    confirmPassword: 'මුරපදය තහවුරු කරන්න',
    confirmPasswordPlaceholder: 'මුරපදය නැවත ඇතුළත් කරන්න',
    selectBank: 'බැංකුව තෝරන්න',
    phoneNumber: 'දුරකථන අංකය',
    reImport: 'නැවත ආයාත කරන්න',
    obtainingCertification: 'සහතිකය ලබා ගනිමින්',
    enterVerificationCode: 'සත්‍යාපන කේතය ඇතුළත් කරන්න',
    enterVerificationCodeTitle: 'සත්‍යාපන කේතය ඇතුළත් කරන්න',
    enterVerificationCodePlaceholder: 'සත්‍යාපන කේතය ඇතුළත් කරන්න',
  },
  AccumulatedPointsThisMonth: 'මෙම මාසයේ එකතු වූ ලකුණු',
  ConvertibleAmount: 'පරිවර්තනය කළ හැකි මුදල',
  vipMedalTip1: 'ඊළඟ මට්ටමට උසස් වීමට',
  vipMedalTip2: 'දින 0.2කින් යාවත්කාලීන වේ',
  errorMessage1: 'සේවාදායක අභ්‍යන්තර දෝෂයකි',
  errorMessage2: 'වලංගු නොවන ඉල්ලීමකි',
  errorMessage3: 'අවසර අවශ්‍යයි',
  errorMessage4: 'දත්ත නොමැත',
  errorMessage5: 'පරිශීලක නාමය හෝ මුරපදය වැරදියි',
  errorMessage6: 'පැරණි මුරපදයකි',
  errorMessage7: 'පරිශීලක නොමැත',
  errorMessage8: 'ශේෂය ප්‍රමාණවත් නොවේ',
  errorMessage9: 'අවම මුදල',
  errorMessage10: 'පැරණි පරිශීලකයෙකි',
  errorMessage11: 'මිනිත්තු 5කින් පසු නැවත උත්සාහ කරන්න',
  errorMessage12: 'සත්‍යාපන කේතය වැරදියි',
  errorMessage13: 'මුරපද ඇතුළත් කිරීමේ සීමාව ඉක්මවා ඇත',
  errorMessage14: 'පරිශීලක අවහිර කර ඇත',
  errorMessage15: 'මුදල වලංගු නොවේ',
  errorMessage16: 'කූපන් කේතය දැනටමත් පවතී',
  errorMessage311: 'ගිණුම් පිහිටුවීමට අපව අමතන්න',
  deadline: 'අවසන් දිනය',
  depositAmountAtLeast: 'අවම තැන්පතු මුදල',
  depositAmountAtMost: 'උපරිම තැන්පතු මුදල',
  withdrawAmountAtLeast: 'අවම ආපසු ගැනීමේ මුදල',
  withdrawAmountAtMost: 'උපරිම ආපසු ගැනීමේ මුදල',
  transfering: 'මාරු කරමින්',
  transferFailed: 'මාරු කිරීම අසාර්ථකයි',
  balance: 'ශේෂය',
  liveSlotsBonus: 'සජීවී සහ ස්ලොට් බෝනස්',
  cash: 'මුදල්',
  confirmMessage: 'ඔබට විශ්වාසද?',
  walletType: 'පසුම්බි වර්ගය',
  normalBalance: 'සාමාන්‍ය ශේෂය',

  registerTip: 'ඔබගේ ගිණුම දැනට පරීක්ෂා කිරීමක් යටතේ පවතී. කරුණාකර පරීක්ෂාව සම්පූර්ණ වනතුරු රැඳී සිටින්න.',
  registerSuccess: 'ලියාපදිංචිය සාර්ථකව',
  registerRefuseTip: 'ලියාපදිංචිය ප්‍රතික්ෂේප කර ඇත. ප්‍රශ්න තිබේ නම්, කරුණාකර පාරිභෝගික සේවා මධ්‍යස්ථානය අමතන්න.',
  errorMessage20: 'ඔබගේ ගිණුම දැනට පරීක්ෂා කිරීමක් යටතේ පවතී. කරුණාකර පරීක්ෂාව සම්පූර්ණ වනතුරු රැඳී සිටින්න.',
  duplicateDepositOrderInfo:
    'ඔබේ නම සහ තැන්පතු මුදල පිටපත් වන බැවින්, තැන්පතු මුදල {amount} දක්වා යාවත්කාලීන කර ඇත. කරුණාකර තැන්පතු ඇණවුම නැවත ඉදිරිපත් කරන්න.',

  about: {
    title: 'Terms of Use',
    two:
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    <strong>1.&nbsp;</strong><span style="background-color: initial"><strong>Introduction, Definitions, and Regulations</strong></span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    <span style="background-color: initial">\n' +
      '      <span style="color: #f1c40f">\n' +
      '        <a style="color: #f1c40f" href="https://www.LuckCoinz.biz/alternate-url">\n' +
      '          <span style="background-color: initial">LuckCoinz</span>\n' +
      '        </a>\n' +
      '      </span>\n' +
      '    </span>\n' +
      '    may update, modify, edit, and supplement the regulations at any time. Substantial modifications to LuckCoinz’s regulations must be communicated to the account holder in advance and require their consent. If you do not agree to the amendments to LuckCoinz’s regulations, you cannot continue to use the service.\n' +
      '    </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    1.1 "Account Holder" refers to an individual who has created a LuckCoinz account on the website and has a contractual relationship with LuckCoinz.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    1.2&nbsp;<span style="background-color: initial">\n' +
      '      >"LuckCoinz Account" is an account owned by the account holder for conducting transactions, and is strictly intended for establishing commercial relationships with LuckCoinz, and for betting, gaming, and gambling transactions.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    ><span style="background-color: initial">1.3&nbsp;</span>\n' +
      '    ><span style="background-color: initial">\n' +
      '      >"Contract" refers to the agreement between LuckCoinz and the account holder, which must be constituted in accordance with LuckCoinz’s regulations, i.e., "Terms of Use".\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    <span style="background-color: initial">1.4&nbsp;</span>\n' +
      '    <span style="background-color: initial">\n' +
      '      "Website" refers to the internet gateway accessible through the internet address of\n' +
      '      <span style="color: #f1c40f">\n' +
      '        <a style="color: #f1c40f" href="https://www.LuckCoinz.biz/alternate-url">\n' +
      '          <span style="background-color: initial">LuckCoinz</span>\n' +
      '        </a>\n' +
      '      </span>\n' +
      '      >, where all the latest and relevant information regarding the operation of LuckCoinz is posted, and services are provided to account holders through the website.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    <span style="background-color: initial">1.5&nbsp;</span>\n' +
      '    <span style="background-color: initial">\n' +
      '      >"Service" refers to the games and betting provided to the account holder by LuckCoinz through the website.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.6 "Card" refers to all types of cards with "payment", "billing", "debit", "credit", "virtual", or similar functions.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.7 "Payment Solution Provider" is an intermediary acting as an agent for various payment methods.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.8 "Financial Institution" refers to a bank or other institution regulated by the relevant domestic financial service provisions or similar laws.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.9 "Force Majeure" refers to events or conditions beyond control that delay or prevent a party from fulfilling its contractual obligations. For the purposes of LuckCoinz’s regulations, force majeure includes natural disasters, government regulations (including cases where necessary permits are denied or revoked through no fault of the party), wars, conflicts, riots, civil disturbances, terrorism, fires, explosions, floods, theft, malicious damage, strikes, or other causes beyond the party’s control.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.10 "Client Application" refers to the web browser program that the account holder must run to use the service.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.11 "Software" refers to all software used by LuckCoinz to provide or operate the service or to run the client application.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.12 "Goods" refers to amounts, bonuses, or rewards that the account holder can obtain.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.13 "Inactive Account" refers to a LuckCoinz account that has no login or logout records for 12 consecutive months.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.14 All trademarks, service marks, and trade names, as well as images, graphics, text, concepts, or knowledge found on the website (collectively "Intellectual Property"), and the client application and materials contained herein, are the exclusive property of LuckCoinz or LuckCoinz’s suppliers and partners. The account holder may not use or exploit the intellectual property for any purpose except as permitted by law.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    ><span lang="EN-US"><span style="font-weight: bold">2.&nbsp;</span></span>\n' +
      '    ><span style="background-color: initial"><strong>Account Regulations</strong></span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    2.1 Creating a LuckCoinz Account\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    &nbsp; &nbsp; 2.1.1 Except for account holders, individuals cannot participate in games for monetary purposes. To register as a player, you must sign up personally and submit an application form. The registration requires the following information.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.1 "Account Holder" refers to an individual who has created a LuckCoinz account on the website and has a contractual relationship with LuckCoinz.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    1.2&nbsp;<span style="background-color: initial">\n' +
      '      "LuckCoinz Account" is an account owned by the account holder for conducting transactions, and is strictly intended for establishing commercial relationships with LuckCoinz, and for betting, gaming, and gambling transactions.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    <span style="background-color: initial">1.3&nbsp;</span>\n' +
      '    <span style="background-color: initial">\n' +
      '      "Contract" refers to the agreement between LuckCoinz and the account holder, which must be constituted in accordance with LuckCoinz’s regulations, i.e., "Terms of Use".\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    ><span style="background-color: initial">1.4&nbsp;</span>\n' +
      '    ><span style="background-color: initial">\n' +
      '      >"Website" refers to the internet gateway accessible through the internet address of\n' +
      '      <span style="color: #f1c40f">\n' +
      '        ><a style="color: #f1c40f" href="https://www.LuckCoinz.biz/alternate-url">\n' +
      '          <span style="background-color: initial">LuckCoinz</span>\n' +
      '        </a>\n' +
      '      </span>\n' +
      '      >, where all the latest and relevant information regarding the operation of LuckCoinz is posted, and services are provided to account holders through the website.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    ><span style="background-color: initial">1.5&nbsp;</span>\n' +
      '    ><span style="background-color: initial">\n' +
      '      >"Service" refers to the games and betting provided to the account holder by LuckCoinz through the website.\n' +
      '    </span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.6 "Card" refers to all types of cards with "payment", "billing", "debit", "credit", "virtual", or similar functions.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.7 "Payment Solution Provider" is an intermediary acting as an agent for various payment methods.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.8 "Financial Institution" refers to a bank or other institution regulated by the relevant domestic financial service provisions or similar laws.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    1.9 "Force Majeure" refers to events or conditions beyond control that delay or prevent a party from fulfilling its contractual obligations. For the purposes of LuckCoinz’s regulations, force majeure includes natural disasters, government regulations (including cases where necessary permits are denied or revoked through no fault of the party), wars, conflicts, riots, civil disturbances, terrorism, fires, explosions, floods, theft, malicious damage, strikes, or other causes beyond the party’s control.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.10 "Client Application" refers to the web browser program that the account holder must run to use the service.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.11 "Software" refers to all software used by LuckCoinz to provide or operate the service or to run the client application.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.12 "Goods" refers to amounts, bonuses, or rewards that the account holder can obtain.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    1.13 "Inactive Account" refers to a LuckCoinz account that has no login or logout records for 12 consecutive months.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p>&nbsp;</p>\n' +
      '<p>\n' +
      '  <span style="color: #ffffff">\n' +
      '    >1.14 All trademarks, service marks, and trade names, as well as images, graphics, text, concepts, or knowledge found on the website (collectively "Intellectual Property"), and the client application and materials contained herein, are the exclusive property of LuckCoinz or LuckCoinz’s suppliers and partners. The account holder may not use or exploit the intellectual property for any purpose except as permitted by law.\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    ><span lang="EN-US"><span style="font-weight: bold">2.&nbsp;</span></span>\n' +
      '    ><span style="background-color: initial"><strong>Account Regulations</strong></span>\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    >2.1 Creating a LuckCoinz Account\n' +
      '  </span>\n' +
      '</p>\n' +
      '<p class="MsoNormal">&nbsp;</p>\n' +
      '<p class="MsoNormal">\n' +
      '  <span style="color: #ffffff">\n' +
      '    >&nbsp; &nbsp; 2.1.1 Except for account holders, individuals cannot participate in games for monetary purposes. To register as a player, you must sign up personally and submit an application form. The registration requires the following information.\n' +
      '  </span>\n' +
      '</p>\n',

    tree:
      '<p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="font-weight: bold">3.&nbsp;</span><span style="background-color: initial"><strong>Betting Rules</strong></span></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">3.1 Betting Operators accept bets on sports or events with undetermined outcome using a telecommunications network.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >3.2 When signing up for an account using an account placing bets participating in games and winning winnings regardless of the media resource (Internet or mobile service) the client\n' +
      ' By downloading the Application, the user agrees to the current version of the Terms and agrees to act in accordance with the Terms.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >3.3 <span style="color: #f1c40f"><a style="color: #f1c40f" href="https://www.LuckCoinz.biz/우회주소">LuckCoinz</a></span\n' +
      '                    reserves the right to change or add to the terms of this document without individual prior written notice, and will notify users by means of a notice. Exist\n' +
      ' The conditions of accepted bets remain unchanged and any subsequent acceptance of bets will be subject to the new rules.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">3.4 HELLO88reserves the right to refuse to provide services to individuals without giving a reason.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>4. WEBSITE USE POLICY</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    4.1 The LuckCoinz shall not be liable for any loss or damage resulting from the use of the Site or its contents. This clause does not apply to personal content\n' +
      'Use or abuse, inability to access or use the Site, delay in the operation of the Site, delay in the transmission of information, communication line failure, errors, typographical errors, errors or omissions in the Site, etc.\n' +
      'The same applies.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >4.2 Photos and videos from the Website and its sub-sites are for personal use only. The publication, transmission or reproduction of information for any other purpose is strictly prohibited\n' +
      '                   it is forbidden</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >4.3 HELLO88actively monitors the traffic on the website and reserves the right to block access to any bots.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>5. Glossary</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">5.1 In the current version of the Terms, the basic terms are as follows.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">single a bet on a single event.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >"Combination" - a bet on the outcome of several events at the same time. If you are betting on more than one event (e.g. two different soccer matches), \n' +
      'Bets can be configured as "combinations". To win an accumulator bet, not a single prediction among the events included in the accumulator bet must be off\n' +
      ' should be. If any of the bets in the accumulator fail to predict, all bets lose. The winnings of accumulator bets are equal to each of the included multipliers\n' +
      ' is calculated as the value multiplied by all (if one of the bets is returned, the odds for that bet are calculated as 1) multiplied by the bet amount.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >"System" - A bet consisting of the "accumulator bets" of an event. The number of combinations in the system is calculated by the possible parlay bets in the system. System\n' +
      ' To win a bet, the number of predictions shown in the total number of predictions must be correct. (Defined as: For example, the system "3/4" is one of the 4 predictions\n' +
      ' 3 must hit, and "5/7" must hit 5 out of 7.) Only a fraction of the total bet amount (proportional to the number of options) is placed on accumulator bets within the system\n' +
      ' Since the winnings of system bets are lower than those of accumulator bets on these events, this should be taken into account before placing a bet. In addition\n' +
      ' In some cases, when some of the results are returned, the winnings may be less than the bet amount\n' +
      ' there are.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      ' >"Chain" - A "single" combination of bets on events that are independent of each other. Within the chain, the sum of a single bet on each event is equal to the sum of the chain. The customer is the chain\n' +
      ' You can set the order of bets, and you can only determine the bet amount for the first event in the order. Accordingly, the "chain account" in which the winnings are accumulated \n' +
      ' is made. The sum of the chain accounts is calculated after the progress of each single bet included in the chain. The initial sum of the chain account is equal to the sum of the first bet\n' +
      ' It is the same.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <ul>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff"\n' +
      '                      >If the sum of the chain accounts is less than the initial sum, the first bet, then a single bet on the next event will be wagered with the total remaining amount.</span\n' +
      '                    >\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff">The order of calculation in the chain is based on the order of the bets you have determined, not in the order of time.</span>\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff">After all bets on the chain have been calculated, the sum of the remaining funds in the chain is account is the winnings.</span>\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff">If the amount remaining in the chain is account reaches zero, the chain will be broken and the bet will be considered lost.</span>\n' +
      '                  </li>\n' +
      '                </ul>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      ' > "Handicap" - is offered by the bookmaker, and it gives the participant in the match a preemption in the choice of goals, points, sets, etc. Of events with a handicap\n' +
      'The outcome is determined as follows: The handicap given to the participant will be added to the result of the match. The participant selected as a result of applying the handicap \n' +
      'If the winner is in favor, the participant is considered a winner and the bet is a winner. Winnings will be paid out according to the payouts stated in the betting information. But the handicap \n' +
      'If the applied result is in favour of the opponent, the bet loses. In case of a tie, the odds are calculated as 1 (return of bet).</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">Example:</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <table style="border-collapse: collapse; width: 110%; height: 100px; margin-left: -5%; margin-right: auto" border="1">\n' +
      '                  <tbody>\n' +
      '                    <tr>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">Team 1</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">Team 2</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">HandyCap 1</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">Odd 1</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">HandyCap 2</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"\n' +
      '                          ><strong><span style="font-size: 14px">odds 2</span></strong></span\n' +
      '                        >\n' +
      '                      </td>\n' +
      '                    </tr>\n' +
      '                    <tr>\n' +
      '                      <td style="width: 16.6667%; text-align: center"><span style="font-size: 14px; color: #ffffff">Team A</span></td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"><span style="font-size: 14px">Team B</span></span>\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"><span style="font-size: 14px">-1.5</span></span>\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"><span style="font-size: 14px">2.25</span></span>\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"><span style="font-size: 14px">+1.5</span></span>\n' +
      '                      </td>\n' +
      '                      <td style="width: 16.6667%; text-align: center">\n' +
      '                        <span style="color: #ffffff"><span style="font-size: 14px">1.9</span></span>\n' +
      '                      </td>\n' +
      '                    </tr>\n' +
      '                  </tbody>\n' +
      '                </table>\n' +
      '                <h5>&nbsp;</h5>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial"><span style="font-size: 14px">1.&nbsp;</span></span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      Suppose you want to bet on > Cagliari to win the handicap. The handicap bet on Cagliari (Handicap 2) is "+1.5". Handicap "+1.5"\n' +
      'To place a bet on the handicap +1.5 will be added to Cagliari is scoring calcend, so Cagliari must not lose by more than 1 goal. The following situations\n' +
      'Lets find out.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial">&nbsp; &nbsp; &nbsp;a.&nbsp;</span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >For example, if the final score is 2-1 and the handicap (+1.5) is added to the result, the score is 2:2.5 for Cagliari\n' +
      ' Its a win. The bet is a win.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial">&nbsp; &nbsp; &nbsp;b.&nbsp;</span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >For example, if the final score is 2-0 and the handicap (+1.5) is added to the result, Inter Milan win 2:1.5.\n' +
      ' A bet is a loser.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial">2.&nbsp;</span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >Similarly, considering Inter Milan handicap "-1.5", you can bet on Inter Milan win as follows:</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial">&nbsp; &nbsp; &nbsp; a. </span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >For example, if the final score is 3-0 and the handicap (-1.5) is subtracted from the result, it will be 1.5:0 for Inter Milan\n' +
      ' Its a win. The bet is a hit.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial">&nbsp; &nbsp; &nbsp; b.&nbsp;</span\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >In the event of a win or draw by 1 goal or a win for Inter Milan, for example, the final score is 2-1 and the handicap (-1.5) is subtracted from the result, which equals 0.5:1 for Cagliari\n' +
      ' Its a win. Inter Milans handicap -1.5 bet is a loss.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >"Asian Handicap" - An Asian Handicap is also offered, where you bet on two handicaps. Asian Handicap and Over/Under are multiples of 0.25 (but not 0.5\n' +
      ' Considered win-loss or over/under bets. These bets are counted as two bets, each of which is half of the stake amount, the same multiplier,\n' +
      ' Bets on a multiple of 0.5 near the amount of "Handicap Criteria" or "Over/Under Criteria". If the Asian Handicap is included in an accumulator or system bet, single\n' +
      ' It is calculated with the same odds as the bet. If both bets in the Asian Handicap win, the bets odds of "X" will be counted. If one \n' +
      ' If bet wins and the other bet is returned, the odds are calculated as ("X"+1)/2. If both bets are unsuccessful, the bet is \n' +
      ' is considered a defeat.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <table style="border-collapse: collapse; width: 100%; height: 140px; margin-left: auto; margin-right: auto" border="1">\n' +
      '                  <tbody>\n' +
      '                    <tr>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">Team</span></td>\n' +
      '                      <td style="width: 33.3333%; text-align: center">\n' +
      '                        <span style="font-size: 14px; color: #ffffff">two handicap bets asian handicap</span>\n' +
      '                      </td>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">Odds</span></td>\n' +
      '                    </tr>\n' +
      '                    <tr>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">Team A</span></td>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">-0.25 (0, -0.5)</span></td>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">2.0</span></td>\n' +
      '                    </tr>\n' +
      '                    <tr>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">Team B</span></td>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">+0.25 (0, +0.5)</span></td>\n' +
      '                      <td style="width: 33.3333%; text-align: center"><span style="font-size: 14px; color: #ffffff">1.8</span></td>\n' +
      '                    </tr>\n' +
      '                  </tbody>\n' +
      '                </table>\n' +
      '                <h5>&nbsp;</h5>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >If 200 Euros is wagered on "Team A" to win, half of the stake will be wagered on each of the two bets, the winnings are 100 * 2.0+\n' +
      '                      100 * 2.0 = 400 euros. If a match ends in a draw, one bet loses, the other bet is returned as "1".,\n' +
      '                      The winnings are calculated as 100 * 0.0 + 100 * 1.0 = 100 EUR. In case of "Real Madrid" losing, both bets lose, winnings are 100 *\n' +
      '                      0.0 + 100 * 0.0 = 0 EUR. If 200 euros is wagered on "Barcelona" to win, the winnings are 100 * 1.8 + 100 *\n' +
      '                      1.8 = 360 euros. If a match ends in a draw, one bet will be a win and the other bet will be returned as "1".,\n' +
      '                      The winnings are calculated as 100 * 1.8 + 100 * 1.1 = 280 euros. In case of "Team B" losing, both bets lose, winnings are 100 * 0.0\n' +
      ' + 100 * 0.0 = 0 Euro.</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">"Total" – the total number of goals, puck or points scored by a participant or participant in a match.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">예:</span></p>\n' +
      '                <ul>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff"\n' +
      '                      >Assuming the total number of goals scored in a soccer match is "4", then the given sum is over (over) or under (under), and the bet is \n' +
      'offers.</span\n' +
      '                    >\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff"\n' +
      '                      >If the total number of goals scored in a match is under 4 goals (0-0, 1-0, 0-1, 0-1, 1-1, etc.), bets on "4" under are winning.</span\n' +
      '                    >\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff"\n' +
      '                      >If the total number of goals scored in a match is over 4 (4-1, 4-2, 3-2 etc), bets on "4" under are lose, and bets on "4" over are winning.</span\n' +
      '                    >\n' +
      '                  </li>\n' +
      '                  <li>\n' +
      '                    <span style="color: #ffffff"\n' +
      '                      >If the total number of goals scored in a match is exactly 4 goals (3-1, 4-0, 2-2, 1-3, 0-4), all bets on "4" Over and Under will be returned.</span\n' +
      '                    >\n' +
      '                  </li>\n' +
      '                </ul>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><strong><span style="background-color: initial">6. Betting Policy</span></strong></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >6.1 The values of the variables (handicap, total, etc.) and the number of matches stated on the day are subject to change after betting, but the situation remains the same before betting. However, there are no technical issues orn' +
      '                    The exception to this is when there is an obvious mistake.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">6.2 The amount of the bet will be confirmed by us and only the recognized amount will be accepted.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >6.3 Only the user is responsible for the betting. Once a bet has been placed and the acceptance is confirmed, the user can change or cancel the bet \n' +
      ' No, there is not. <a href="https://www.LuckCoinz.biz/ Address"><span style="color: #f1c40f">LuckCoinz</span></a\n' +
      ' > is not responsible for any "losses" or "double" bets made by customers. Make sure you check all the information carefully before placing your bets\n' +
      ' please.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">6.4 Loss of password is not a reason to incur a withdrawal fee or cancel the payment of winnings.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >6.5 Bets are accepted upon confirmation by us. All accepted bets will be marked in the "Bet History" field. Sometimes there is a mark on the bet history\n' +
      ' but there may be a delay. In the event of a dispute, the time at which the bet was registered in our system will be considered the actual time. If the user bets \n' +
      ' Even if you do not receive a notification of acceptance, it will be considered accepted if it appears in the "Bet History".</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >6.6 Once a bet has been registered on the server, failure to communicate or failure to contact the customer due to technical problems is not grounds for cancellation of the bet.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>7. 정보 표시</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    ><span style="background-color: initial"\n' +
      '                      >7.1 The Company is not responsible for the accuracy, completeness and timeliness of the proposed information services, including information on real-time matches and mailing services\n' +
      '                      .</span\n' +
      '                    ></span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.2 <span style="color: #f1c40f"><a style="color: #f1c40f" href="https://www.LuckCoinz.biz/우회주소">LuckCoinz</a></span\n' +
      '                    >makes every effort to correctly display information about live matches (e.g. results, match times, team/player statistics). However, this information is \n' +
      ' is indicated for the sole purpose of providing it. LuckCoinz is not responsible if this information is incorrect.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.3 HELLO88is not responsible for and does not accept any claims related to team names and player names translated from foreign languages.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.4 There is more than one player with the same surname in the event/match shown, and the player is name or the other player can be uniquely identified\n' +
      ' If the feature is not indicated, all bets will be returned.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.5 If one or more participants are incorrectly listed in the lineup in the match name or venue name (excluding translation errors), the other team/participant in the tournament will be replaced by \n' +
      ' If  is indicated, all bets on the match will be void and will be returned. Only LuckCoinz can determine which events fall into this category\n' +
      ' there is.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.6 A specific age group (e.g. 18-21 years old/youth team), gender (women), or reserve team status is not indicated in that lineup, so it is not a confirmed lineup\n' +
      ' If another team is line-up is shown, all bets on that match will be void. However, the name is not written all the way down in the lineup, or it is a grammatical error\n' +
      ' Bets are valid in case of. If the gender of the team is not indicated in the lineup, the gender of the team will be considered male. If the women is team is \n' +
      'If you are participating in a game, you must make a detailed statement about it in your lineup. Otherwise bets on the match will be returned.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.7 The dates and times shown in the line-up are the dates and times when betting on the match (excluding live matches) will cease. In addition, this means that the actual match start\n' +
      ' It does not necessarily coincide with the date and time. The actual start time of the match is based on the official documents of the competition organiser and the information of the LuckCoinz\n' +
      ' is considered the start time.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>',

    four:
      '<p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.8 If the venue is not indicated in the lineup, the organizer of the match will be listed first, except for U.S. sport-type events. In the given information\n' +
      ' Any errors that may occur cannot be grounds for the return of bets.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.9 When accepting bets on live matches, there will be a set delay depending on the sport, bet type and other features, and the security and protection of future bets\n' +
      ' is determined solely by LuckCoinz.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.10 Considering the nature of live matches, where the odds are constantly changing, you can choose one of the following for your convenience: "Change of all dividends\n' +
      ' Accept the matter Accept only bets with increased odds or Do not accept when the odds change respectively\n' +
      ' Decide whether to accept the changed dividend if it exceeds the clients existing dividend.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">7.11 Incorrectly stated match dates will not be grounds for cancellation of bets.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >7.12 If a match has started from the point where it was previously abandoned and no line-up is specified, bets will be returned.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>8. Relevant Matches and Results</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="background-color: initial; color: #ffffff"\n' +
      '                    >8.1 In "combination" and "system" bets, other events in the same match, even if they are interdependent, should be included together\n' +
      ' is prohibited. If an event related to the same match is included in the bet, the bet will not be sanctioned and accepted in the program, but the bet will not be regulated\n' +
      ' Bets will be returned. LuckCoinz reserves the right to decide whether or not the events are associated.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >8.2 For leagues that determine the advancing team in "Combine" and "System" betting, bets can only be placed on one team from that group. If within the same group\n' +
      ' If the team that qualified and did not advance is included, the bet will be returned even if the bet is not sanctioned and accepted in the program.\n' +
      ' LuckCoinz reserves the right to decide whether to accept bets relating to qualified or unqualified teams in "Combination", "System" and "Single" bets\n' +
      ' there are.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>9. Obvious errors and technical errors</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="background-color: initial; color: #ffffff"\n' +
      '                    >9.1 We are not responsible for any errors in the entry, movement or counting of bets. In particular, the LuckCoinz provides information on the calculation of the payout and amount of bets\n' +
      ' reserves the right to correct any errors that occur during typing (e.g. errors in odds assigned to teams, misspelled matches, etc.), \n' +
      ' Bets can be declared void even after the match is over. If a correction is made due to an obvious error, the bet will be returned. The most common\n' +
      ' The type of technical error is as follows:</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.1.1 Odds input error - when the odds (or handicap/total settings, etc.) are displayed without separators (dots) or with additional numbers.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >&nbsp; &nbsp; &nbsp; &nbsp;For example, if you see 18 instead of 1.8, 23 instead of 2, 30 instead of 3, etc.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.1.2 "Mirror error" - when the odds of the participating teams are reversed, in which case the team with the lower odds (including live matches) will be replaced by the team with the higher odds\n' +
      ' It is written as a dividend.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >&nbsp; &nbsp; &nbsp; &nbsp;For example, basketball. The win in Barcelona vs Unicaya is shown as 4 and 1.2 instead of 1.2 and 4.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.1.3 Writing error - when all odds are displayed correctly, but the teams are displayed in the wrong order (usually played on a neutral field\n' +
      ' Occurs in the game).</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >&nbsp; &nbsp; &nbsp; &nbsp; In the previous example: basketball. Win of the match between Barcelona and Unicaya is 1.2 and 4 instead of the win of the match of Unicaya vs Barcelona\n' +
      ' 1.2 and 4.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >&nbsp; &nbsp; &nbsp; &nbsp; An indicator of an obvious error is the very large difference in dividends between LuckCoinz and other bookmakers, which is impossible in the current situation\n' +
      ' This is the case when dividends are given. In no case is it obvious\n' +
      '                  </span>\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >&nbsp; &nbsp; &nbsp; &nbsp; Whether there was an error or technical error reserves the right of the LuckCoinz to determine.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.2 LuckCoinz reserves the right to cancel deliberate bets on incorrect odds and manifest errors and will immediately notify the client of any change in reserves\n' +
      '                    .</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.3 LuckCoinz reserves the right to cancel bets even after the end of the match, even if the customer has been awarded a winning prize due to a technical error or manifest error.\n' +
      '                    All Rights Reserved.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.4 If, due to technical issues, the required amount of a customer s bet is not deducted from their reserve, the bet will be considered void.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.5 In the event that the clients account is incorrectly credited with funds, the client shall notify the LuckCoinz immediately. If an error results in the client s reserve\n' +
      ' If it is negative, we reserve the right to cancel any pending bets, even if the bet was placed before the error occurred.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >9.6 The Company shall be liable to the User for any damages caused by the intentional use of technical or administrative errors when depositing and withdrawing money\n' +
      ' reserves the right to bear.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>10.Credit Bets</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >10.1 LuckCoinz does not accept any bets on credit and does not provide any related services. All bets are placed on the user s account\n' +
      ' must be used.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>11.LegalAspects</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >11.1 Participation in games such as LuckCoinz betting, sports betting, multiplayer poker, casino games, lotteries, etc., is restricted by law in some countries or \n' +
      ' It states that it may be prohibited. These restrictions do not constitute any authorization required by us to place bets or to the betting company (bets or game configuration\n' +
      ' license).</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >The User is prohibited or permitted to place bets or participate in games such as sports betting, multiplayer poker and lotteries, only under certain conditions, which can be referred to\n' +
      ' It should be noted that the user is solely responsible for any damage caused by non-compliance.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >In addition, customers should note that we are under no obligation to provide customers with more extensive information, guidance and warnings than we currently do. In particular, we use \n' +
      ' We are not responsible for any damages caused by violations of applicable laws and regulations in the customer is country.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >11.2 Depending on your country of residence, you will be required to register on one of the websites belonging to the LuckCoinz. By participating in the game, the user agrees to the terms and conditions in accordance with the laws of the country\n' +
      ' You are the minimum age to consent, and you acknowledge that you are able to enter into a contract with us. If these conditions are not met, the user s account will be suspended\n' +
      ' Necessary measures will be taken.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >11.3 The User shall automatically use the personal data provided to the Company in the form of betting, multiplayer poker games, casino games, lotteries, etc. and other games in which multiple players are played\n' +
      ' I agree to be stored and used.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >11.4 If you have more than once signed up for a website that is part of a LuckCoinz group of companies, or if you have more than one account, we will, at our discretion, transfer all of your accounts to \n' +
      ' You have the right to block and leave only one account that corresponds to your country of residence. By signing up, you agree to the proprietary intellectual property of our regulations and to us\n' +
      ' I agree to the system managed by.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">11.5 We reserve the right to inform users about special promotions and new products by phone and e-mail.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >11.6 The legal relationship between you and us shall be governed by the laws of Curaçao and governed by the laws of private law without regard to applicable legal rules.\n' +
      ' The place of fulfillment of all obligations of betting and gaming is Curacao. Any dispute arising out of or in connection with the matter raised by the customer shall be subject to the jurisdiction of the relevant jurisdiction\n' +
      ' It will be resolved by the courts of Curaçao.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">11.7 We state that we do not provide you with advice on tax and legal matters.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >11.8 If the Company complies with the arbitration clause, any dispute regarding betting could lead to litigation, legal action, and a claim for a bookmaker is license\n' +
      ' No, there is not. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >11.9 In the event of claims and disputes on the part of the user and those representing them, our liability shall be as the size of the bet or the winnings of the bet\n' +
      ' is limited. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      ' <p><span style="color: #ffffff">11.10 In the event of a dispute or claim, the LuckCoinz is database will take precedence over other information.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >11.11 In the event of any discrepancy between the English version and any translation into any other language, the English version shall be deemed correct. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >11.12 All disputes, including claims about the outcome of a match, must be requested within 5 days of the end of the match to be reviewed by the Bookmaker\n' +
      ' approved. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >11.13 LuckCoinz does not hold the user is funds in segregated or separately protected bank accounts. The user does not have LuckCoinz deposits in the bank\n' +
      ' It is important to understand that you do not accrue interest on it. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"><strong>12. Bet Limit</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >12.1 The Bookmaker Company reserves the right not to accept bets without explaining the reasons, and may close the User is account without prior notice, or \n' +
      ' You reserve the right to block you temporarily</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >12.2 The minimum and maximum bet amounts for all sporting events are prescribed by the Bookmaker and are subject to change without prior written notice. In addition\n' +
      ' <span style="color: #f1c40f"><a style="color: #f1c40f" href="https://www.LuckCoinz.biz/ bypass">LuckCoinz</a></span\n' +
      ' > reserves the right to impose special restrictions on individual users accounts. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >12.3 LuckCoinz means that, for the avoidance of suspicion, more than one user will not bet from one IP address (to the same computer or to the same local network\n' +
      ' I would recommend. In such cases, it is necessary to explain the situation, adjusting the possibilities of the LuckCoinz and the game. LuckCoinz these users accounts \n' +
      ' You can block it and ask for documents to verify your identity. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >12.4 Winnings will only be paid out within our maximum limit. If the User has placed a bet and the winnings exceed the maximum limit, the Company will set the maximum limit\n' +
      ' I don it pay more than </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >12.5 Sports results will be reported on the official website of the LuckCoinz within 3 days after the end of the match,\n' +
      '                    <a\n' +
      '                      class="css-1rn59kg"\n' +
      '                      title="http://gbcy555.com/"\n' +
      '                      href="http://gbcy555.com/"\n' +
      '                      data-testid="link-with-safety"\n' +
      '                      data-renderer-mark="true"\n' +
      '                      ><strong data-renderer-mark="true">G</strong></a\n' +
      '                    >will be published on amblecity.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>13. LateBets</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >13.1 In all cases where bets are placed after the start of the match, except live matches, bets are void and can be returned, and in "Accumulating" and "System" bets\n' +
      ' Excluded. All bets (including live betting) on matches where the outcome is already known at the time of betting will be considered void and will be refunded.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >13.2 In case of objecting to the start time of the match, the user must provide the official documentation (or link to the association is website for the match\n' +
      ' should.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >13.3 In the event of a dispute regarding the start time of a match, if the official association of the match does not provide such information, the match start time\n' +
      ' Only the information in the LuckCoinz is considered correct and the bet is processed.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>14. 반환</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >14.1 If a bet is "returned" then the bet is considered a win with odds of 1. In the case of a "single" bet, the amount you bet on this bet is \n' +
      ' means that it will be returned. For "Accumulating" or "System" bets, the total odds will change depending on the return. (If returned, the corresponding multiplier is equal to 1\n' +
      ' will be processed, and the rest of the odds will remain unchanged. The final odds are applied to the odds of the returned match among the odds in the "combination" or "system", and the odds are 1\n' +
      ' is calculated.)</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>',

    five:
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >15.1 The User is responsible for maintaining the confidentiality of all information associated with his/her account, and to prevent the use of the account by unauthorized persons \n' +
      ' All measures must be taken. All actions taken with the account holder is ID and password are valid and have legal effect. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >15.2 If a user suspects that his or her ID and password are known by a third party, he or she may at any time request LuckCoinz to temporarily block the account\n' +
      ' has the right to do so. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >15.3 In order to ensure the security of your personal information, LuckCoinz will contact you via the email you provide when you register. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                   >15.4 The User is responsible for maintaining the confidentiality of all information relating to his/her e-mail and undertakes all \n' +
      'to prevent the use of e-mail from third parties.' +
      'You need to take action. LuckCoinz shall not be liable for any loss or damage resulting from any act resulting from any communication between the user using the registered email address and the contact between us\n' +
      ' is not responsible. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"><strong>16. Match-fixing</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      ' <span style="color: #ffffff"\n' +
      ' >16.1 If there is a possibility of interference in the outcome of the match and the calculation of odds (including in cases of suspected match-fixing), the LuckCoinz shall pay for the match temporarily\n' +
      ' We reserve the right to suspend or cancel the payment of such bets and may provide information to the relevant authorities for further investigation and prevention of recurrence\n' +
      ' there is. </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >16.2 LuckCoinz reserves the right to suspend payment and process the return of the match if there is solid evidence that any negotiations have been made on the match\n' +
      ' holds. This can be based on the size, number and type of a single bet or all possible bets.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >Decisions from relevant federal and regulatory agencies are also included in the consideration. In such cases, the LuckCoinz will investigate further and prevent recurrence of the incident against the parties involved\n' +
      ' reserves the right to provide information to government agencies.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>17. Neutral Zone</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >17.1 If a match is transferred to Neutral Zone (NF) then all bets are valid. However, if the match is transferred to an opponent is region, a refund will be issued.\n' +
      ' If the teams are from the same city and only the region has been transferred, bets will be valid and no refunds will be given. In international matches, the match goes to the opponent\n' +
      ' refunds will only be processed if they are transferred.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>18. Suspended Matches</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >18.1 If the match is not played for the required time set by our regulations, and even if the result of the abandonment of the match is later confirmed as final\n' +
      ' Matches are considered abandoned matches.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">18.2 If a match has been played for the following hours, the match will be considered.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <ul>\n' +
      '                  <li><span style="color: #ffffff">Soccer - 90 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">Futsal (2 x 20 minutes of game time) - 35분</span></li>\n' +
      '                  <li><span style="color: #ffffff">Futsal (2 x 25 minutes of game time) - 45 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">NBA Basketball (National Basketball Association, PBA, CBA) - 43 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">European Basketball - 35 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">Hockey NHL - 55 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">Bandy - 60 mins</span></li>\n' +
      '                  <li><span style="color: #ffffff">European Hockey - 50 mins</span></li>\n' +
      '                  <li><span style="color: #ffffff">American Football - 55 minutes</span></li>\n' +
      '                  <li><span style="color: #ffffff">Baseball - 5 innings</span></li>\n' +
      '                  <li><span style="color: #ffffff">Handball - 50 minutes</span></li>\n' +
      '                </ul>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >18.3 An abandoned match that is deemed to have been abandoned shall be held unless otherwise stated in the sport is regulations, provided that the result is clearly stated at the time of suspension\n' +
      ' All bets will be refunded. Bets on performance (such as a certain period of time) associated with an abandoned match will also be returned.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>19. Changes to the game</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >19.1 If the start time of a match is changed and not specified in the rules of the sport, all bets on the match will be refunded.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >19.2 During a match, if the duration of the match is changed to the next day or the venue is changed on the way to the next round, bets will stand. Any \n' +
      ' If the process to the next round is canceled for any reason, the cancellation will be ignored and the payment will be processed according to the original result. If the second match does not take place\n' +
      ' If it is abandoned, all bets will be refunded, including during that process. Payouts for courses will only be accepted on bets placed prior to the first match. If any game\n' +
      ' If it does not proceed, the bet will be refunded.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>20. Calculating Bets</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="background-color: initial; color: #ffffff"\n' +
      '                    >20.1 HELLO88은 경기 종료 후, 공식 결과와 대체 정보(경기의 비디오를 포함)를 기준으로 결과를 검증합니다. 베팅 계산은 웹사이트<a\n' +
      '                      style="color: #ffffff"\n' +
      '                      href="https://www.LuckCoinz.com/ko"\n' +
      '                    >\n' +
      '                    </a\n' +
      '                    ><a href="https://www.LuckCoinz.biz/우회주소"><strong data-renderer-mark="true">G</strong>AMBLECITY</a>in the "Results" column of \n' +
      ' based on the content. If there is a discrepancy in the results from different sources of information, or in case of obvious error, the final decision to determine the result shall be \n' +
      ' Decide in the LuckCoinz.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.2 All inquiries made by the User regarding the accuracy of the results of the LuckCoinz is website should be based solely on official sources of information (the website of the official institution concerned).\n' +
      ' Information from other sources cannot be considered as a basis for reviewing and re-evaluating bets.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.3 Valid (actual) results announced based on official or other information will be considered results immediately after the end of the event.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p><span style="color: #ffffff">We use the following official sources.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <ul>\n' +
      '                  <li><span style="color: #ffffff">www.uefa.com - Champions League, UEFA Cup, Qualifiers and European Championships (Soccer)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.fifa.com - Confederations Cup, Qualifiers and World Championships (Soccer)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.beto.gr - Greek Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.bundesliga.at - Football in Austria</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.turkish-soccer.com - 터키 축구</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.rfpl.org - Football in Russia</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.premierleague.com - English Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.sportinglife.com - English Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.gazzetta.it - Italian Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.bundesliga.de - German Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.marca.com - Spanish Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.lfp.es - Spanish Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.lfp.fr - French Football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.mlsnet.com - American Soccer</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.goalzz.com - Soccer</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.nfl.com - American football</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.basket.ru - Basketball in Russia</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.nba.com - Basketball (NBA)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.euroleague.com - European Basketball Leagues</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.ulebcup.com - Basketball (ULEB)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.nhl.com - Hockey (NHL)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.fhr.ru - Russian Hockey</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.rusbandy.ru - Russian Bandy</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.atptennis.com - Tennis (ATP)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.wtatour.com - Tennis (WTA)</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.mlb.com - Baseball</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.eurohandball.com -Handball</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.fide.com - Check</span></li>\n' +
      '                  <li><span style="color: #ffffff">www.volley.ru - Volleyball in Russia</span></li>\n' +
      '                </ul>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">We may use other official sources when exceptionally necessary.</span></p>\n' +
      '                <p><span style="color: #ffffff">We reserve the right to review the video to determine the actual results.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.4 The result of live betting will be settled based on the announcement made immediately after the end of the match. Any of the following changes (e.g. after the match, referee is decision\n ' +
      ' reversal) will not be taken into account when processing the result of bets. We make these bets based on statistical information about the actual course of the match on our own\n' +
      ' process. Unless otherwise stated, all bets on any player who does not take part in the match will be void.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">20.5 If our statistics (based on TV broadcasts) differ from the official statistics, bets will be calculated according to our statistics.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.6 If more than one participant or team is declared the winner of the match, the odds will be divided by the number of winners. (The payout can be less than 1\n' +
      ' No, there is not.).</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">20.7 All actions on the games that are played on your account will be in the currency you selected when you signed up.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.8 All bets whose outcome is already known (in particular the next goal scorer or the winner of a specific category of the match) will be considered valid even if the match is abandoned\n' +
      ' is considered.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.9 If a match is not completed and deemed incomplete, all bets will be refunded, even if the result of the abandoned match is declared final.\n' +
      '(except for points in 18.2)</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >20.10 If no special rules are violated in individual sports, results announced on the day of the race will be counted towards the result of the match. Results from relevant associations\n' +
      ' Due to modifications and team disciplinary action, changes to the result will not be considered for betting purposes and will be treated as the original result.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>21. 베팅 단체 / 연합과 경기 제한</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >21.1 Users can only place bets for themselves. Repeated bets involving the same selection from more than one customer are \n' +
      ' is considered invalid and may be revoked by us. Even after the official results are announced, users may collude, act as a coalition, or \n' +
      ' If suspicious bets are found by more than one user within a short period of time, such bets will be considered void and cancelled by us\n' +
      ' processed.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>',

    one:
      '<p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >21.2 Limits for sporting events are set according to the status of the user is personal account and betting history. If the user sets the specified limit for the match\n' +
      'If you attempt to go over, a warning will appear on your bet slip stating "Maximum bet amount - limit reached / Official limit exception". Same \n' +
      'within the same match' +
      'Bets that combine selections are not permitted. If you ignore the warning and do not comply with this rule, the accepted bet will be canceled and the initial bet\n' +
      ' amount will be refunded.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">21.3 No Match</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >21.4 In the absence of a breach of special rules in individual sports, if a participant in a match does not participate in the competition for any reason, bets in this regard shall be \n' +
      ' is considered valid, and the customer is treated as lost. The exceptions are.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <ul>\n' +
      '                  <li><span style="color: #ffffff">Matches/Tournaments Cancelled</span></li>\n' +
      '                  <li><span style="color: #ffffff">Matches/Tournaments Void</span></li>\n' +
      '                  <li><span style="color: #ffffff">Change of venue of the match</span></li>\n' +
      '                  <li><span style="color: #ffffff">Matches/Tournaments Suspended</span></li>\n' +
      '                </ul>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >21.5 For bets with two outcomes, such as "Whose taller is taller", if one of the participants refuses to participate before the match/tournament, he\n' +
      ' Bets will be refunded.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>22. Sports Regulations</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">22.1 Additional information about the types of bets on various sports is indicated in the sports regulations.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">22.2 The sports rules take precedence over the general rules.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">22.3. Some bet types</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >22.4 For bets on "Odd/Even Total", regardless of the sport and selection (total score/card/foul etc), if the result is "0", the bet will be set to "Even"\n' +
      ' is calculated.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >22.5 All bets on the number of medals will be calculated by the official medal table at the end of the match. Any changes made later by the relevant authorities will not be taken into account\n' +
      ' does not.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff">22.6 The medals won by the national team in any competition are counted as one, regardless of the number of participants in the team.</span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >22.7 If an item has an option of "Other", the result will include all match participants not listed separately in that option.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >22.8 If there is a match in "Extra Markets" such as Total Score, Win, Loss, Draw, Yellow Card etc., only matches that are included in the line-up for that day will be considered.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>23. Joined</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.1 가Only qualified users are eligible to participate in our bonus program. To sign up, you must fill out the sign-up form correctly.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.2 Registration and betting are only allowed to those who are at least 18 years old (or within the acceptable age range for depositing and betting in your country). Users know that they live \n' +
      ' is solely responsible for the legality of internet gambling where it is located and for providing the relevant information about winnings to the authorities of the country concerned. We will provide you with the relevant documents\n' +
      ' Before receiving, we reserve the right to require proof of an adult and block the account.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.3 When you sign up, all information must be accurate. Especially when using a credit or debit card, the cardholder is first and last name must match the sign-up form\n' +
      'If you do it, your account will be blocked. Before the account is blocked, all bets placed will be considered valid.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.4 We reserve the right to block the accounts of users who submit false information and refuse to pay any winnings. At our request,\n' +
      ' The user must submit an ID (copy of passport) that can be identified, as well as an official document confirming the authenticity of the address and phone entered with a photo\n' +
      ' should be.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.5 Each user can have only one account. Registered users can re-register as a new customer with a new name or a new email\n' +
      ' No, there is not. In case of breach of these rules, we may void all bets placed by the user.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">23.6 The User is not authorised to allow any third party to use his or her Account.</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.7 You must not send us your credit card details or other sensitive financial information via unencrypted email.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.8 LuckCoinz allows all users to choose their own combination of ID and password. Users are required to keep relevant information secret\n' +
      ' should be. Once the ID and password are entered correctly on the website, all bets will remain in progress and can be cancelled or changed by the user\n' +
      ' No, there is not. If you believe that someone else knows your password, please change it immediately on the website. Set all or part of the password\n' +
      ' If you have forgotten it, please contact customer service for a new password.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >23.9 Users who access the LuckCoinz and place bets on their mobile phones will not be liable for any loss of information on their mobile phones.' +
      ' It should be noted that we are not responsible for the fees of mobile operators and Internet operators. By signing up, the customer agrees to these terms and conditions and\n' +
      ' accept.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>24. 통화</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >24.1 Currently, users can place bets in the following currencies: KRW. LuckCoinz reserves the right to block bets and related actions in any of the specified currencies\n' +
      ' I have. In this case, the required payouts to the account in the blocked currency will be held in a different currency that corresponds to the bank is exchange rate for that day.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>25. Bonus Program</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >25.1 All Rewards of the User shall be collected in the form of personal, home address, telephone number, e-mail, one billing bill (such as a card or Skrill account) and the \n' +
      ' Limited to computers (including offices, internet cafes, and public places). We reserve the right to refuse bonuses to our users. Bonus\n' +
      ' The program is only available to users who have loaded their LuckCoinz account with real money.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >25.2 LuckCoinz is in violation of the requirements of the bonus program, and if there is repeated bets on the same match, regardless of the outcome of the bets\n' +
      ' In the event of any evidence or conspiracy by users, the right shall be made to cancel the bonus of such users and void the bets\n' +
      ' there is. In order to prevent fraud, we reserve the right to require you to provide proof of identity before any award can be converted.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>26. Deposit</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >26.1 The available payment methods are determined by the country and currency selected at the time of registration. All of the fees, limits, and other items are deposited and withdrawn\n' +
      ' page. LuckCoinz reserves the right to change these terms and details.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >26.2 When you make a payment, the name of the debit/credit card or bank account holder must exactly match the name of the account holder on the LuckCoinz.\n' +
      ' If it doesn t match\n' +
      '                  </span>\n' +
      '                </p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >LuckCoinz reserves the right to cancel any payment and return any bets placed on another person is account or debit/credit card.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"><strong>27. Deposits to your account</strong></span>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >27.1 If any amount has been credited to the User in error, the User shall notify the Company immediately. The customer is winnings incurred due to these errors will be \n' +
      ' will be considered void and all such bets will be refunded regardless of the time of occurrence and perception of the error.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >27.2 If the deposits in the Account are made for purposes other than betting, poker and casino (in particular, fraudulent money laundering), we will cancel all deposits and give the User \n' +
      ' We reserve the right to recover all costs associated with the deposit.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >27.3 Upon the User is withdrawal request, if the deposit amount exceeds the bet amount, the User reserves the right to charge all costs associated with the deposit and withdrawal\n' +
      ' there are.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <strong><span style="color: #ffffff">28. Amount Restrictions</span></strong>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >28.1 The minimum single bet amount for all matches is 5,000 KRW or the equivalent in the currency of the User is account. The minimum combination or system bet amount is \n' +
      ' 5,000 won or the equivalent of the currency of the user is account. Unless otherwise stated, only one bet is allowed per sporting event. Bets about \n' +
      ' The maximum bet amount must be less than or equal to the amount of the last deposit.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >28.2 The maximum multiplier for accumulator bets is 100,000. Accumulator bets with odds exceeding the maximum will be calculated as multipliers of 100,000. In this case, the winnings\n' +
      ' The sum must not exceed the maximum winnings for a single bet. The maximum multiplier for each item in the system bet is 100,000. Odds are maximum\n' +
      ' Items in the system bet exceeding are calculated with odds of 100,000.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >28.3 The maximum bet amount for each discipline will be determined by the sport and the event, each match and bet type will be determined by the bookmaker s network, in advance of the written stake\n' +
      ' Subject to change without notice. LuckCoinz reserves the right to limit the maximum bet amount for individual matches, as well as the user\n' +
      'without reason and explanation' +
      ' You can add and remove restrictions on your account.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >28.4 Different bet types, different odds markets, the same match, the same selection\n' +
      ' All amount limits apply to the account of an individual user or user association who has placed more than one bet. Two or more bets are placed in this way\n' +
      ' If you confirm that it has been placed, all bets will be considered void and only the first bet will be processed.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <strong><span style="color: #ffffff">29. Payments / Withdrawals</span></strong>\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.1 Withdrawals will be processed within 72 hours from the time the request is received. The first \n' +
      'to the user via electronic payment (such as Skrill, credit or debit card)' +
      ' Before withdrawing, customers must attach copies of documents for identification. Please be aware that counterfeiting is severely punished by law, and you can counterfeit electronically\n' +
      ' If LuckCoinz suspects that you have edited a document,  reserves the right to send it to the appropriate regulatory authority.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.2 Before payment is made, our staff will check the match between the first name, last name, date of birth of the Client and other data. Actual Info\n' +
      ' If any discrepancy is found between the information provided by the customer, LuckCoinz will refund all bets unless the user proves the identity and accuracy of the information entered by the user\n' +
      ' and reserves the right not to pay out winnings.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.3 If it is found that you have opened multiple accounts with us, LuckCoinz reserves the right to refuse payment for these accounts and \n' +
      ' there is. (Unless the user is assets have been lawfully transferred to the account after a penalty of 10% of the total deposit amount has been paid).</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.4 At the time of the first application for cash withdrawal, the User must provide valid passport details in the language of the country of issuance of the passport (English in the case of a foreign passport) accurate\n' +
      ' must be entered.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.5 Organisations and family members are required to regulate their personal relationship with each other, and payments will only be made in the name of the account holder.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.6 The User agrees to provide LuckCoinz with information about his/her bank account in order to transfer winnings.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p><span style="color: #ffffff">29.7 LuckCoinz is not responsible for changes in payouts due to currency fluctuations (exchange rates).</span></p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.8 If the User has applied for a withdrawal of KRW 1,000,000 or more (or the equivalent amount converted into another currency using the exchange rate), the LuckCoinz shall be \n' +
      ' Pay the transfer and associated fees. Otherwise, the fee must be paid by the user to the bank.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.9We reserve the right to withdraw payments using the priority of the withdrawal method (including credit/debit card or bank account) for the winners.\n' +
      '                   </span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>\n' +
      '                <p>\n' +
      '                  <span style="color: #ffffff"\n' +
      '                    >29.10 Before applying for the first withdrawal, the user must use 100% of the deposit amount for betting. This condition does not allow fraud by the user and "money laundering" \n' +
      ' was introduced not to prevent.</span\n' +
      '                  >\n' +
      '                </p>\n' +
      '                <p>&nbsp;</p>',
  },

  gameRespon: {
    one: 'Responsible Gaming',
    two: 'LuckCoinz is committed to supporting responsible gaming and raising awareness about gambling addiction, focusing on prevention, adjustment, and treatment improvements.',
    three:
      "LuckCoinz's responsible gaming policy aims to minimize the negative impacts of gambling addiction and to improve responsible gaming practices.",
    four: 'LuckCoinz provides a variety of games and entertainment to online customers and takes responsibility for the product lineup.',
    five: "LuckCoinz's goal is to provide the world's safest and most innovative gaming platform for adults. Our products and services are available only within each user's economic capability, ensuring they receive the highest quality service. Integrity, fairness, and reliability are key principles of LuckCoinz. Therefore, it is clear that LuckCoinz must do its best to help customers who seem to be struggling with gambling addiction due to excessive gaming. At the same time, it is important to respect the rights of people participating in games of chance and treat gaming as a form of entertainment.",
    six: "LuckCoinz's responsible gaming is based on three principles: player protection, game protection, and protection from gambling addiction. LuckCoinz, along with research institutions, associations, and treatment organizations, is building a responsible, safe, and reliable system for online gambling.",
    seven: 'Player Protection',
    eight:
      'LuckCoinz is responsible for protecting players. Player protection involves preventing minors from participating in games, protecting personal information, including handling personal data and payment processing. The fairness and randomness of the provided products are closely monitored by independent organizations. Marketing materials are also created with player protection and fairness in mind.',
    nine: 'Protection from Gambling Addiction: Research - Prevention - Intervention',
    ten: 'The majority of players who engage in sports betting, casino betting, and other games enjoy them moderately as entertainment. Certain habits and behaviors that are generally harmless (e.g., shopping, sports, dining, or drinking) can sometimes become addictive and problematic for some individuals. The same applies to sports betting and games, which can cause issues for a small number of customers.',
    eleven:
      'Customers showing signs of gambling addiction may be banned from certain products or even all games. Additionally, customers showing signs of gambling addiction will be provided with contact information for organizations that offer expert advice and support.',
    twelve: 'The Most Effective Preventive Measure is Self-Responsibility',
    thirteen:
      'LuckCoinz’s fundamental principle is to create a safe and responsible online environment where customers can enjoy betting. LuckCoinz can provide means to help customers control their gambling, but customers’ self-responsibility plays a significant role in utilizing these means, which is the most effective protection against gambling addiction. LuckCoinz is committed to supporting customers by providing transparent means to control their gambling and information on expert advice and support for those experiencing gambling addiction.',
    fourteen: 'Protection of Minors',
    fifteen:
      'Minors (under 18 or below the minimum gambling age in their jurisdiction) are not allowed to participate in games and betting. For this reason, verification of age and date of birth is a mandatory condition at the registration stage. LuckCoinz takes the participation of minors in games and betting very seriously and seeks the support of parents and guardians to protect minors. LuckCoinz advises customers to store their account information (ID and password) in a secure place and not share it with anyone.',
    sixteen:
      'Additionally, LuckCoinz recommends installing internet filtering software. This software can restrict access to inappropriate internet content for children and adolescents.',
    seventeen: 'Responsibility for Issues',
    eighteen:
      'LuckCoinz offers a variety of games and betting options for the entertainment of the majority of customers. At the same time, the company is responsible for maintaining a safe and enjoyable environment by considering related risk factors and providing support and means.',
    nineteen:
      'Customers who find it challenging to recognize their limits or control their gambling due to addiction are unable to enjoy games responsibly as entertainment. LuckCoinz takes responsibility for these users by blocking access to specific products for them.',
    twenty: 'Know the Key Issues!',
    twentyOne:
      'Most people play games for enjoyment and participate in games moderately within their financial means. However, for a small number of people, gambling is not just entertainment, and this should be taken seriously.',
    twentyTwo:
      'Gambling addiction is considered a behavior that jeopardizes the individual’s or their family’s life, work, financial status, or health. Long-term gambling can have negative consequences, making it unproductive for such individuals.',
    twentyThree:
      'In 1980, pathological gambling addiction was officially recognized and included in the DSM-IV and ICD-10 psychological disorder lists. This addiction is defined by long, repetitive, and increasing gambling tendencies despite the negative personal and social consequences like debt, family disruptions, and hindered personal development.',
    twentyFour: 'What Types of Gambling Behavior are Considered Problematic?',
    twentyFive:
      'Although the diagnosis of gambling addiction can only be confirmed by experts, the materials provided on this webpage will help customers assess and evaluate their gambling behavior.',
    twentySix:
      'Addictions that are not substance-related are challenging to identify as enjoyment or problems. However, there are some diagnostic signals to recognize the current issue.',
    twentySeven:
      'Customers experiencing at least five of the following symptoms are more likely to have gambling addiction problems:',
    twentyEight: 'Preoccupation with gambling and neglecting other matters.',
    twentyNine: 'Increasing bet amounts when losing.',
    thirty: 'Unsuccessful attempts to stop or control gambling.',
    thirtyOne: 'Restlessness, irritability, or depression when limiting gambling.',
    thirtyTwo: 'Viewing gambling as a way to escape problems.',
    thirtyThree: 'Regularly tracking losses.',
    thirtyFour: 'Lying about the way they gamble.',
    thirtyFive: 'Committing illegal acts.',
    thirtySix: 'Experiencing strained or broken relationships with family and colleagues.',
    thirtySeven: 'Borrowing money for gambling.',
    thirtyEight: 'Ways to Gamble Responsibly',
    thirtyNine:
      'Gambling should not be seen as a means of earning money or escaping daily problems, and customers are encouraged to gamble within their financial capabilities. Here are some simple tips:',
    forty: 'Do not see gambling as a way to earn money; consider it an entertainment expense.',
    fortyOne:
      'Take regular breaks; set a timer and stop when the time is up. The more time you spend gambling, the more you might lose.',
    fortyTwo: 'Gamble only with money you can afford to lose; do not use money meant for bills.',
    fortyThree: 'Decide in advance how much to spend and stop when it’s gone.',
    fortyFour: 'Do not increase your limit after setting a maximum.',
    fortyFive:
      'Never chase losses; if you set a loss limit and try to recover some losses, you are not setting a limit.',
    fortySix: 'Do not gamble when under the influence of alcohol or drugs.',
    fortySeven: 'Do not gamble when feeling depressed or upset.',
    fortyEight: 'Stress and Emotional Decisions Make It Harder to Make Judgments',
    fortyNine: 'Self-Exclusion, Time-Out, and Reality Check',
    fifty:
      'LuckCoinz offers self-exclusion, time-out, and reality check features for customer self-management. Self-exclusion can be applied for 6 months to 5 years at the customer’s decision. Choosing self-exclusion means customers cannot access their accounts until the period expires. Accounts cannot be reactivated during the self-exclusion period, and LuckCoinz will take all possible measures to prevent customers from opening new accounts during this period.',
    fiftyOne:
      'LuckCoinz strives not to send any marketing or promotional materials during the self-exclusion period. After the self-exclusion period ends, customers can contact customer service to reactivate their accounts after a 24-hour “cooling-off period.” Ongoing bets will remain, and all winnings and account balances will be refunded during self-exclusion.',
    fiftyTwo:
      'LuckCoinz customers can apply a time-out to their account for 24 hours, 1 week, 1 month, or 2 months. The period can be set within the account or by contacting customer service.',
    fiftyThree:
      'LuckCoinz customers can apply reality checks to their account for 10 minutes, 20 minutes, 30 minutes, 1 hour, 2 hours, 4 hours, 6 hours, or 8 hours. The period can be set within the account or by contacting customer service. Customers who wish to permanently close their account due to gambling addiction should contact customer service as soon as possible.',
    fiftyFour:
      'The duration can be set within the account and can also be arranged by contacting customer service. If a customer wishes to permanently close their account due to gambling addiction, they should contact customer service as soon as possible.',
    fiftyFive: 'Once completed, customers will not be able to reactivate their account under any circumstances.',
  },

  privacy: {
    one: 'Privacy Policy',
    two: 'LuckCoinz values your personal information and is committed to protecting your privacy to the best of its ability.',
    three:
      'LuckCoinz informs you about how your personal information is used and the measures taken by the company to protect your personal information.',
    four: 'This Privacy Policy describes how LuckCoinz handles your personal information when you use one of our websites.',
    five: 'If you do not agree with the contents of this Privacy Policy, please refrain from proceeding with any further actions on our website.',
    six: 'By creating an account, placing bets, or transferring funds on our website, you are deemed to have agreed to our Terms of Service and Privacy Policy.',
    seven:
      'You have the right to stop using the website at any time, but we may be required to legally retain some of your personal information.',
    eight:
      'We may periodically update this Privacy Policy and will notify you of changes by posting the revised terms on our platform.',
    nine: 'We recommend regularly reviewing this Privacy Policy.',
    ten: 'Our Information Protection Officer',
    eleven:
      'If you have concerns about how LuckCoinz handles personal information or need further information, you can contact our representative via email at verify@LuckCoinz.com.',
    twelve: 'Information We Collect About You',
    thirteen:
      'Personally Identifiable Information: You provide this information during account registration, placing bets, and using our website services. This information is necessary to allow us to grant access to our website and related services and is collected in the following cases:',
    fourteen: 'When registering for an account with LuckCoinz',
    fifteen: 'When voluntarily providing information to use the website',
    sixteen: 'When publicly disclosing information on the website',
    seventeen: 'When contacting customer support',
    eighteen: 'This information includes:',
    nineteen: 'ID',
    twenty: 'First and last name',
    twentyOne: 'Date of birth',
    twentyTwo: 'Date of birth',
    twentyThree: 'Email address',
    twentyFour: 'Residential address',
    twentyFive: 'Phone number',
    twentySix: 'Billing address',
    twentySeven: 'Identification documents',
    twentyEight: 'Proof of address documents',
    twentyNine: 'Transaction history',
    thirty: 'Website settings',
    thirtyOne: 'Other information provided while using our platform',
    thirtyTwo: 'Credit/debit card details or other payment information',
    thirtyThree: 'This information is also necessary for billing purposes and to protect minors.',
    thirtyFour: 'Non-Personal Identifiable Information and Traffic Analysis',
    thirtyFive:
      'LuckCoinz strives to make our website as user-friendly and easily accessible on the internet as possible.',
    thirtySix: 'LuckCoinz collects information about website usage that does not directly identify users.',
    thirtySeven:
      'When using the service, our servers maintain active logs that collect specific administrative and traffic information. This information includes IP addresses, access times, access dates, number of webpage visits, language preferences, software crash reports, and browser types.',
    thirtyEight: 'This information is essential for providing and improving the quality of our services.',
    thirtyNine: 'Cookies',
    forty:
      'LuckCoinz uses cookies to ensure that our website operates efficiently and to enhance the quality of your experience when visiting the platform. For more details, please refer to our Cookie Policy.',
    fortyOne: 'Use of Your Personal Information and Purposes',
    fortyTwo: 'We use your personal information in the following categories:',
    fortyThree: 'When providing requested products or services',
    fortyFour: 'to meet legal or regulatory obligations;',
    fortyFive: 'To monitor website performance',
    fortySix: 'When providing marketing information',
    fortySeven:
      'Your rights regarding your personal information vary depending on the category and legal basis to which such information falls.',
    fortyEight:
      'This section provides detailed information about each category, its permissions, and how to use these permissions.',
    fortyNine: 'These permissions are displayed in bold in each category.',
    fifty: 'Providing products and services',
    fiftyOne:
      'We use your personal information to enable you to use our website, sign up for an account, participate in our online casino, and provide support to our customer service providers.',
    fiftyTwo:
      'We share your information with external organizations that act on our behalf to provide you with our products and services.',
    fiftyThree: 'More information can be found in the Information Sharing section.',
    fiftyFour: 'This category includes essential activities necessary to provide the service you use or subscribe to.',
    fiftyFive:
      'If you do not agree with us using your information in this way, we recommend that you stop using our Services and close your account.',
    fiftySix: 'Legal and regulatory requirements',
    fiftySeven:
      'LuckCoinz verifies your details with personally identifiable information and proof of address to protect our users from fraudulent activity and promote responsible gaming.',
    fiftyEight:
      'We may conduct security reviews at any time to check your service and financial transaction history for potential violations of our Terms and applicable laws',
    fiftyNine:
      'A security review may include requesting your credit report or checking information you have provided against third-party databases.',
    sixty:
      'We must carry out these activities to ensure that we provide our products and services legally and responsibly and in accordance with the requirements set by regulatory authorities. Without performing these activities, we cannot provide our services, and if you do not agree to the following terms of use of your information, we recommend that you not use our services and cancel your account.',
    sixtyOne: 'Website performance monitoring',
    sixtyTwo: 'We use cookies and traffic analysis to improve the quality of our website and services.',
    sixtyThree:
      'We pursue our legitimate interests in carrying out these activities and ensure that the impact on your privacy is minimal.',
    sixtyFour:
      'If you believe that your privacy rights outweigh our legitimate interests, you have the right to object to the activities we carry out.',
    sixtyFive:
      'However, because this activity is important to our business, if you wish to object beyond managing cookies, you may need to close your account.',
    sixtySix: 'marketing',
    sixtySeven: 'If you give your consent, we will send you marketing materials via email, SMS or online.',
    sixtyEight: 'We do not share your information with third parties for their marketing purposes.',
    sixtyNine: 'You can withdraw your consent or renew your marketing preferences at any time.',
    seventy: 'your rights',
    seventyOne: 'right to amendment',
    seventyTwo:
      'If you believe that personal information we hold about you is incorrect, you have the right to have it corrected.',
    seventyThree: 'For information that cannot be updated through My Profile, please contact verify@LuckCoinz.com.',
    seventyFour: 'The right to request a copy of your personal information',
    seventyFive:
      'If you would like a copy of the personal information we hold on you, please request a copy via live chat or email verify@LuckCoinz.com and we will send you a form to complete. This form is not required, but it helps us provide you with the information you seek in a timely manner.',
    seventySix:
      'To protect the security of your personal information, we will ask you for identification information and will respond within one month of receiving it. If your request is unusually complex and likely to take more than a month, we will let you know as soon as possible how long this will take, but a fee may apply for these requests.',
    seventySeven: 'Right to lodge a complaint with a regulatory body',
    seventyEight:
      'If you believe that your privacy rights have been violated or you disagree with a decision we have made regarding your privacy rights, you have the right to lodge a complaint with your local supervisory authority or where the infringement occurred.',
    seventyNine: 'right to erasure',
    eighty: 'If you no longer need the use of your personal information, you can request us to delete it.',
    eightyOne: 'This right applies only in specific situations and is not guaranteed or absolute.',
    eightyTwo: 'The right to deletion does not apply if the information is required for any of the following reasons:',
    eightyThree:
      'To exercise freedom of expression and information, to comply with a legal obligation, to perform a function in the public interest or in the exercise of official authority, for record keeping for purposes in the public interest, for scientific historical research or statistical purposes. , also *for the creation, exercise or defense of legal disputes, deletion is not possible or processing such requests is difficult.',
    eightyFour: 'Sharing of Personal Information',
    eightyFive: 'We may disclose your personal information to third parties:',
    eightyFive2: 'To comply with legal or regulatory obligations',
    eightySix: 'To enforce or apply these terms or other terms',
    eightySeven:
      'To support the provision of products and services you have requested, including third-party software providers',
    eightyEight:
      'If, at our discretion, you have engaged in fraudulent activities such as game manipulation or payment fraud',
    eightyNine: 'For research purposes related to addiction prevention (information is anonymized)',
    ninety: "To protect our, our customers', or others' rights, property, or safety",
    ninetyOne: 'With your consent',
    ninetyTwo:
      'Personal information collected through services may be stored and processed in countries where we or our affiliates, suppliers, or agents operate. By using our services, you explicitly consent to the transfer of your information outside your country. We take reasonable measures to ensure that personal information transferred outside the EEA or jurisdiction is handled as securely as it would be within the EEA or jurisdiction.',
    ninetyThree: 'Measures include, but are not limited to:',
    ninetyFour: 'Binding Corporate Rules',
    ninetyFive: 'Model Contract Clauses',
    ninetySix: 'US/EU Privacy Shield',
    ninetySeven: 'Security',
    ninetyEight: 'We understand the importance of security and the technology needed to protect information.',
    ninetyNine:
      'We store all personal information received directly from you in encrypted databases within a secure network behind state-of-the-art firewall software.',
    oneHundred: '(Our services support SSL version TLS 1.2 and 128-bit encryption).',
    oneHundredOne:
      'We also strive to ensure that our affiliates, agents, partners, and suppliers implement appropriate security measures.',

    oneHundredTwo: 'Retention',
    oneHundredThree: 'We retain personal information as long as it is required for legal or business purposes.',
    oneHundredFour:
      'When determining the retention period, LuckCoinz considers local laws, contractual obligations, and user expectations and requirements.',
    oneHundredFive: 'When personal information is no longer needed, we securely delete or dispose of it.',

    oneHundredSix: 'Third-Party Practices',
    oneHundredSeven:
      'Third-party online sites are independently owned and operated, so we cannot guarantee the security of information collected by third parties through our services, third-party affiliate programs (if applicable), or other programs.',
    oneHundredEight: 'Information collected by such third parties is managed according to their privacy policies.',
    oneHundredNine: 'Our website may contain links to other websites that are not regulated by this privacy policy.',
    oneHundredTen:
      'If you access other sites through these links, the operators of those sites may collect information in accordance with their own privacy policies, which may differ from ours.',
    oneHundredEleven:
      'We are not responsible for any errors or functionality issues that may arise from linked sites; the responsibility lies solely with the operators of those sites.',

    oneHundredTwelve: 'Analytics',
    oneHundredThirteen:
      'The services included in this section allow the owner to monitor and analyze web traffic and user behavior.',
    oneHundredFourteen: 'Google Analytics (Google Inc.)',
    oneHundredFifteen: 'Google Analytics is a web analytics service provided by Google Inc. ("Google").',
    oneHundredSixteen:
      'Google uses the collected information to track and examine the use of LuckCoinz, prepare activity reports, and share them with other Google services.',
    oneHundredSeventeen:
      "Google may use the collected information for its own advertising network's settings and personalization.",
    oneHundredEighteen: 'Collected personal information: Cookies and usage data',
    oneHundredNineteen: 'Processing location: US - Privacy Policy - Opt-out',
  },

  allGamingPlatforms: 'All gaming platforms',
  playedGamePlatforms: 'Played game platforms',
  gameProvider: 'Game provider',
  notice: 'Notice',
  catalogue: 'catalogue',
  registerInfo: {
    placeholder: "වලංගු තොරතුරු ඇතුළත් කරන්න",
  }
}
