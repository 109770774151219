<script setup>
import { getCurrentInstance, onMounted, provide, ref } from 'vue'
import { useRouter } from 'vue-router'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import BottomFooter from '@/components/BottomFooter/BottomFooter.vue'
import { useConfigStore } from '@/stores/config.js'
import { useUserStore } from '@/stores/user.js'
import { getUnreadMessageCount } from '@/api/messages.js'
import { getToken } from '@/utils/token.js'
import { userHeartbeat } from '@/api/user.js'
import Notify from '@/components/Notify/Notify.vue'
import LoginDialog from '@/components/LoginDialog/LoginDialog.vue'
import { generateFingerprint, getUrlParams } from '@/utils/utils.js'
import { addAgentFingerprintAPI, getFingerprintAPI } from '@/api/fingerprint.js'
import ConfirmDialog from '@/components/ConfirmDialog/ConfirmDialog.vue'
import viberQR from '@/assets/img/v2/viber_qr.jpg'
import { isMobile } from '@/utils/utils.js'
import axios from 'axios'

//判断是否是手机端
if (isMobile() && window.location.pathname !== '/mobile/') {
  location.href = '/mobile/'
}

const showDropdown = ref(false)

const configStore = useConfigStore()
let intervalId = null // 定时器的ID
const maintenStatus = ref(1)
// 定义异步函数来获取数据
const fetchStatus = async () => {
  try {
    const response = await axios.get('https://prod-res-luckcoinz.link-api.com/status')
    maintenStatus.value = response.data
    console.log('状态', maintenStatus.value)
    if (maintenStatus.value === 0) {
      router.push('/Maintenance')
    } else if (router.currentRoute.value.fullPath.startsWith('/Maintenance')) {
      router.push('/')
    }
  } catch (error) {
    console.log('维护状态加载失败')
  }
}

const routerViewRef = ref(null)
onMounted(async () => {
  provide('routerView', routerViewRef.value)
  fetchStatus() // 初始请求
  intervalId = setInterval(fetchStatus, 60000)

  // 获取url中的code，并保存，如果没有则获取指纹
  const code = ref('')
  const fingerPrint = ref('')

  const params = getUrlParams(location.href)
  fingerPrint.value = await generateFingerprint()
  if (localStorage.getItem('code')) {
    code.value = localStorage.getItem('code')
  } else if (params.code) {
    code.value = params.code
    localStorage.setItem('code', code.value)
  } else {
    getFingerprintAPI(fingerPrint.value).then(({ data }) => {
      if (data.data.agent_code !== null) {
        code.value = data.data.agent_code
        localStorage.setItem('code', code.value)
      }
    })
  }
  if (code.value) {
    await addAgentFingerprintAPI({ fingerprint: fingerPrint.value, agent_code: code.value })
  }
})

const isHideHeader = ref(false)
const isHideFooter = ref(false)
const router = useRouter()
router.beforeEach((to, from, next) => {
  if (to.meta.auth && !getToken()) {
    configStore.showLoginDialog = true
  } else {
    isHideHeader.value = ['gameIFrame'].includes(to.name)
    isHideFooter.value = ['gameIFrame', 'bti-sports'].includes(to.name)
    next()
    window.scrollTo(0, 0)
  }
})

const instance = getCurrentInstance()
const i18n = instance.appContext.config.globalProperties.$i18n
i18n.locale = configStore.language

const userStore = useUserStore()
const updateUnreadCount = () => {
  const isLogin = getToken()
  if (isLogin) {
    getUnreadMessageCount().then(({ data }) => {
      userStore.unread_count = data.data.content
    })
    if (userStore.heartbeat) {
      clearInterval(userStore.heartbeat)
    }
    userHeartbeat()
    userStore.heartbeat = setInterval(() => {
      userHeartbeat()
      userStore.refreshToken()
    }, 30000)
  }
}
updateUnreadCount()
setInterval(async () => {
  updateUnreadCount()
}, 300000)

//viberQR
const openViberQR = () => {
  window.open(viberQR, '_blank') // 使用导入的路径
}
</script>

<template>
  <div class="app">
    <div class="header" v-if="!isHideHeader && maintenStatus === 1">
      <TopHeader />
    </div>

    <div class="body" ref="routerViewRef">
      <RouterView />
    </div>

    <div class="footer" v-if="!isHideFooter && maintenStatus === 1">
      <BottomFooter />
    </div>

    <Notify />
    <login-dialog />
    <ConfirmDialog />

    <div class="kefu">
      <img src="@/assets/img/v2/kf2.webp" alt="" @click="showDropdown = !showDropdown"
        v-click-outside="() => (showDropdown = false)" />
      <div v-if="showDropdown" class="dropdown-menu">
        <a href="" target="_blank">
          <img src="@/assets/img/v2/kf2.webp" alt="Telegram" />
        </a>
        <a href="" target="_blank">
          <img src="@/assets/img/v2/whatsapp.webp" alt="Line" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.app {
  min-width: 1440 * @pcx;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 auto;
  width: 100%;
}

.body {
  flex: 1;
  padding: 0 80 * @pcx;
  width: 100%;
  position: relative;
  margin: auto;
  background: #0f1014;
}

.footer {
  flex: 0 0 auto;
  width: 100%;
}

.kefu {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 999;
  transform: translateY(-50%);

  img {
    height: 65 * @pcx;
    object-fit: contain;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    background: #373942;
    border: 1 * @pcx solid #ccc;
    box-shadow: 0 2 * @pcx 10 * @pcx rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    border-radius: 5 * @pcx;
  }

  .dropdown-menu a {
    padding: 27 * @pcx;
  }

  .dropdown-menu img {
    width: 37 * @pcx;
    height: 37 * @pcx;
    max-width: none;
  }
}
</style>
