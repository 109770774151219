/**
 * 验证电子邮件格式是否有效
 * @param {string} email - 要验证的电子邮件地址
 * @returns {boolean} 如果电子邮件格式有效，则返回 true，否则返回 false
 */
const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}
/**
 * 验证手机号码格式是否有效
 * @param phone
 * @returns {boolean}
 */
const phoneValid = (phone) => {
  const phoneRegex = /^10[0-9]{8}$/
  return phoneRegex.test(phone)
}
/**
 * 验证账号是否符合要求
 * @param {string} account - 要验证的账号
 * @returns {boolean} 如果密码有效，则返回 true，否则返回 false
 */
const accountValid = (account) => {
  // 长度至少6位且只能包含字母和数字
  return /^[0-9a-zA-Z]{6,}$/.test(account)
  // const containsNumber = /\d/.test(password)
  // const containsLowercase = /[a-z]/.test(password)
  // const containsUppercase = /[A-Z]/.test(password)
  //
  // return password.length >= 6 && containsNumber && containsLowercase && containsUppercase
}
/**
 * 验证密码是否符合要求
 * @param {string} password - 要验证的密码
 * @returns {boolean} 如果密码有效，则返回 true，否则返回 false
 */
const passwordValid = (password) => {
  // 密码长度至少6位且只能包含字母和数字
  return /^[0-9a-zA-Z]{6,}$/.test(password)
  // const containsNumber = /\d/.test(password)
  // const containsLowercase = /[a-z]/.test(password)
  // const containsUppercase = /[A-Z]/.test(password)
  //
  // return password.length >= 6 && containsNumber && containsLowercase && containsUppercase
}
/**
 * 格式化日期为 'YYYY-MM-DD' 格式
 * @param {Date|string} date - 要格式化的日期
 * @returns {string} 格式化后的日期字符串
 */
const formatDate = (date) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Seoul' }
  return new Date(date).toLocaleDateString(undefined, options).replace(/\//g, '-')
}
/**
 * 格式化日期和时间为 'YYYY-MM-DD HH:MM:SS' 格式
 * @param {Date|string} date - 要格式化的日期和时间
 * @returns {string} 格式化后的日期时间字符串
 */
const formatDateTime = (date) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Seoul',
  }
  return new Date(date).toLocaleString(undefined, options).replace(/\//g, '-')
}
/**
 * 计算几天前0点的时间戳
 * @param {number} day - 天数，表示几天前
 * @returns {number} 几天前0点的时间戳（10位，秒为单位）
 */
function getTimestampDaysAgo(day) {
  const kstOffset = TIMEZONE_OFFSET * 60 * 60 // 韩国时区偏移量，以秒为单位
  const now = new Date() // 获取当前时间
  // 创建几天前的日期对象
  let targetDate = new Date(now.getTime() - day * 24 * 60 * 60 * 1000)
  // 调整到韩国时区
  targetDate.setUTCHours(targetDate.getUTCHours() + 9) // 先加上时区偏移
  targetDate.setUTCHours(0, 0, 0, 0) // 再设置时间为0点
  // 返回几天前0点的时间戳（以秒为单位）
  return Math.floor(targetDate.getTime() / 1000) - kstOffset
}
/**
 * 切割函数，将数组切割为指定长度的子数组列表
 * @param {Array} arr - 需要分组的数组
 * @param {number} size - 每个分组的数组长度
 * @returns {Array} 切割后的子数组列表
 */
const splitList = (arr, size) => {
  return arr.reduce((acc, val, i) => {
    const idx = Math.floor(i / size)
    const existingList = acc[idx] || []
    acc[idx] = existingList.concat(val)
    return acc
  }, [])
}
/**
 * 格式化金额，在千位之间添加逗号
 * @param {number|string} amount - 要格式化的金额
 * @returns {string} 格式化后的金额字符串
 */
const formatAmount = (amount) => {
  if (amount === null) {
    return ''
  }
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
/**
 * 计算给定时间戳距离今天还有多少天
 * @param {number} timestamp 给定的时间戳（单位：秒）
 * @returns {number} 距离今天还有多少天
 */
const daysUntil = (timestamp) => {
  const currentTimestamp = Date.now()
  const givenTimestamp = timestamp * 1000
  const timeDifference = givenTimestamp - currentTimestamp
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
}
/**
 * 复制指定的文本内容到剪贴板。
 *
 * @param {string} text - 需要被复制的文本字符串。
 *
 * @returns {Promise<void>} 返回一个Promise，成功复制则无返回值，失败则抛出错误。
 */
async function copyTextToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text)
  } catch (error) {
    console.error('Failed to copy text to clipboard: ', error)
  }
}
/**
 * 检查当前设备是否为移动设备
 * 通过检测用户代理字符串中的关键词和屏幕宽度来判断
 *
 * @returns {boolean} 如果是移动设备则返回 true，否则返回 false
 */
function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const isMobileUserAgent = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())
  const isMobileScreen = window.innerWidth <= 768
  return isMobileUserAgent || isMobileScreen
}
// 获取url参数
function getUrlParams(url) {
  // 获取问号后面的参数部分
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // 如果没有参数，返回空对象
  if (!queryString) {
    return {}
  }

  // 解析参数字符串为对象
  return queryString.split('&').reduce((params, param) => {
    let [key, value] = param.split('=')
    params[key] = decodeURIComponent(value.replace(/\+/g, ' '))
    return params
  }, {})
}
// 生成指纹
function generateFingerprint() {
  return new Promise((resolve, reject) => {
    if (typeof Fingerprint2 === 'undefined') {
      reject('FingerprintJS2 library is not loaded!')
      return
    }

    Fingerprint2.get((components) => {
      const filteredComponents = components.filter((component) => {
        return (
          component.key !== 'resolution' &&
          component.key !== 'screenResolution' &&
          component.key !== 'availableScreenResolution'
        )
      })

      const values = filteredComponents.map((component) => component.value)
      const murmur = Fingerprint2.x64hash128(values.join(''), 31)

      // 解决 Promise
      resolve(murmur)
    })
  })
}
export {
  isEmailValid,
  splitList,
  passwordValid,
  formatDate,
  formatDateTime,
  getTimestampDaysAgo,
  formatAmount,
  phoneValid,
  daysUntil,
  copyTextToClipboard,
  isMobile,
  getUrlParams,
  generateFingerprint,
  accountValid,
}
