<template>
  <div class="footer">
    <div class="footer-top">
      <div class="log">
        <img src="@/assets/img/logo/luckcoinz.webp" alt="" />
      </div>

      <div class="link-box">
        <div class="links" v-for="(o, i) in linkList" :key="'link-i' + i">
          <div class="link-title">{{ $t(o.title) }}</div>
          <div class="link-list">
            <div class="link-item" v-for="(s, j) in o.list" :key="'link-item-' + j" @click="goView(s.path)">
              {{ $t(s.title) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-copy">
      COPYRIGHT(C) <span style="color: #ff002a">2023 LuckCoinz CASINO</span>. ALL RIGHT RESERVED.
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
const goView = (url) => {
  if (!url) return

  router.push(url)
}

const linkList = [
  {
    title: 'CompanyIntroduction',
    list: [
      { title: 'CompanyIntroduction', path: '/aboutUs' },
      { title: 'TermsOfUse', path: '/terms-and-conditions' },
      { title: 'GameResponsibility', path: '/game-responsibility' },
      { title: 'PrivacyPolicy', path: '/privacy-policy' },
    ],
  },
  {
    title: 'product',
    list: [
      // { title: 'LiveSports', path: '/live-hold' },
      { title: 'OnSiteCasino', path: '/live-casino' },
      { title: 'sports', path: '/sports' },
      { title: 'slot', path: '/slots' },
    ],
  },
  // {
  //   title: 'Statistics',
  //   list: [
  //     { title: 'KoreanView', path: '' },
  //     { title: 'CompetitionResults', path: '' },
  //     { title: 'BusinessAnalysis', path: '' },
  //   ],
  // },
  // {
  //     title: 'help',
  //     list: [
  //         {title: 'SportsBettingRegulations', path: '/sports-betting-game-rules'},
  //         // { title: 'LiveChatShortcuts', path: '' },
  //         // { title: 'FAQ', path: '/support' },
  //     ],
  // },
]
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  height: 150 * @pcx;
  background-color: #1b1f28;
  padding: 25 * @pcx 0 10 * @pcx;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .footer-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 323 * @pcx;

    .log {
      text-align: center;

      img {
        width: 296 * @pcx;
        object-fit: contain;
      }
    }

    .link-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 200 * @pcx;

      .links {
        display: flex;
        align-items: center;

        &:nth-child(n + 2) {
          margin-top: 30 * @pcx;
        }

        .link-title {
          font-family: Roboto, Roboto, sans-serif;
          font-weight: 400;
          font-size: 18 * @pcx;
          color: #ff4a4a;
          line-height: 21 * @pcx;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .link-list {
          margin-left: 10 * @pcx;
          display: flex;
          align-items: center;
          flex-grow: 1;

          .link-item {
            font-family: Roboto, Roboto, sans-serif;
            font-weight: 400;
            font-size: 18 * @pcx;
            color: #595959;
            line-height: 21 * @pcx;
            text-align: left;
            font-style: normal;
            text-transform: none;
            cursor: pointer;

            &:nth-child(n + 2) {
              margin-left: 10 * @pcx;
            }
          }
        }
      }
    }
  }

  .footer-copy {
    font-size: 12 * @pcx;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #636363;
    text-align: center;
  }
}
</style>
