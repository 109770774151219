import service from '@/utils/request.js'

export const loginAPI = (data) => service.post('/user/login', data)
export const registerAPI = (data) => service.post('/user/register', data)
export const getInfo = () => service.get('/user/info')
export const changePasswordAPI = (data) => service.patch('/user/password', data)
export const sendSMSAPI = (data) => service.post('/user/register/sendSMS', data)
export const getAgentExist = (id) => service.get(`/user/agent/${id}/exist`)
export const getUserExist = (id) => service.get(`/user/${id}/exist`)
export const getUserAccountExist = (id) => service.get(`/user/${id}/account_exist`)
export const userHeartbeat = () => service.patch('/user/heartbeat')
export const refreshTokenAPI = () => service.get('/user/refresh_token')
