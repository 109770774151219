<script setup>
import { useCommonStore } from '@/stores/common.js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const { notifyType, showNotify, notifyMessage } = storeToRefs(useCommonStore())
const closing = ref(false)
</script>

<template>
  <transition @beforeLeave="closing = true" @afterLeave="closing = false" name="fade">
    <div
      v-show="showNotify"
      class="relative flex justify-center animate__animated z-[90000]"
      :class="{ animate__fadeIn: !closing, animate__fadeOut: closing }">
      <div class="notify" :class="notifyType">
        {{ notifyMessage }}
      </div>
    </div>
  </transition>
</template>

<style scoped lang="less">
.notify {
  position: fixed;
  width: 95vw;
  margin-top: 2.5vw;
  max-width: 400 * @pcx;
  z-index: 900;
  top: 0;
  display: flex;
  padding: 20 * @pcx;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 16 * @pcx;
  align-items: center;
  font-family: system-ui, 'MS GothicNeo', 'MS Gothic', 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4 * @pcx;
  box-shadow:
    0 * @pcx 3 * @pcx 5 * @pcx -1 * @pcx rgba(0, 0, 0, 0.2),
    0 * @pcx 6 * @pcx 10 * @pcx 0 * @pcx rgba(0, 0, 0, 0.14),
    0 * @pcx 1 * @pcx 18 * @pcx 0 * @pcx rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
}

.error {
  background-color: #d32f2f;
}

.success {
  background-color: #8db40c;
}
</style>
