import axios from 'axios'
import { clearToken, getToken } from '@/utils/token.js'
import router from '@/router'
import { i18n } from '@/main.js'
import { useUserStore } from '@/stores/user.js'
import { useCommonStore } from '@/stores/common.js'
import * as Sentry from '@sentry/vue'

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json' },
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    Sentry.captureException(error, {
      tags: {
        type: 'api_error',
        url: error.config?.url,
      },
    })

    return Promise.reject(error)
  }
)
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { showNotifyMessage } = useCommonStore()
    switch (response.data.status) {
      case 0:
        // showNotifyMessage({ type: 'success', message: $t('success') })
        break
      case 1:
        showNotifyMessage('error', i18n.global.t('errorMessage1'))
        break
      case 2:
        showNotifyMessage('error', i18n.global.t('errorMessage2'))
        break
      case 3:
        showNotifyMessage('error', i18n.global.t('errorMessage3'))
        const userStore = useUserStore()
        userStore.logout()
        setTimeout(() => {
          router.replace('/')
        }, 1000)
        break
      case 4:
        showNotifyMessage('error', i18n.global.t('errorMessage4'))
        break
      case 5:
        showNotifyMessage('error', i18n.global.t('errorMessage5'))
        break
      case 6:
        showNotifyMessage('error', i18n.global.t('errorMessage6'))
        break
      case 7:
        // showNotifyMessage('error', i18n.global.t('errorMessage7'))
        break
      case 8:
        showNotifyMessage('error', i18n.global.t('errorMessage8'))
        break
      case 9:
        showNotifyMessage('error', i18n.global.t('errorMessage9'))
        break
      case 15:
        showNotifyMessage('error', i18n.global.t('errorMessage15'))
        break
      case 20:
        showNotifyMessage('error', i18n.global.t('registerTip'))
        break
      case 21:
        showNotifyMessage('error', i18n.global.t('registerRefuseTip'))
        break
      case 22:
        // showNotifyMessage('error', i18n.global.t('duplicateDepositOrderInfo', { amount: response.data.data.amount }))
        break
      case 311:
        showNotifyMessage('error', i18n.global.t('errorMessage311'))
        break
      default:
        showNotifyMessage('error', response.data.msg)
    }
    if (response.data.status !== 0) {
      return Promise.reject(response)
    }
    return response
  },
  (error) => {
    Sentry.captureException(error, {
      tags: {
        type: 'api_error',
        url: error.config?.url,
      },
    })

    console.log(error)
    try {
      // 登录失效
      if (error.response.data.status === 3) {
        showNotifyMessage('error', i18n.global.t('errorMessage3'))
        const userStore = useUserStore()
        userStore.logout()
        setTimeout(() => {
          router.replace('/')
        }, 1000)
        return
      }
    } catch (e) {
      console.error(e)
    }

    const { showNotifyMessage } = useCommonStore()
    switch (error.response.status) {
      case 401:
        // showNotifyMessage({ type: 'error', message: '登录已过期，请重新登录' })
        clearToken()
        router.replace('/')
        break
      case 403:
        clearToken()
        router.replace('/')
        showNotifyMessage('error', '权限不足')
        break
      case 404:
        showNotifyMessage('error', '请求资源不存在')
        break
      case 500:
        showNotifyMessage('error', '服务器错误')
        break
      default:
        showNotifyMessage('error', error.response.data.message)
    }
    return Promise.reject(error)
  }
)

export default instance
