import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, loginAPI, refreshTokenAPI, userHeartbeat } from '@/api/user.js'
import { clearToken, setToken } from '@/utils/token.js'
import { getUnreadMessageCount } from '@/api/messages.js'
import router from '@/router/index.js'

export const useUserStore = defineStore(
  'user',
  () => {
    const id = ref('')
    const balance = ref('')
    const cash = ref('')
    const rebate = ref('')
    const sport_bonus = ref('')
    const live_slots_bonus = ref('')
    const coupon = ref('')
    const account = ref('')
    const name = ref('')
    const bank_id = ref('')
    const bank_num = ref('')
    const phone_num = ref('')
    const register_ts = ref('')
    const unread_count = ref(0)
    const tokenRefreshTime = ref(0)

    let heartbeat = 0

    function clearInfo() {
      id.value = ''
      balance.value = ''
      cash.value = ''
      rebate.value = ''
      sport_bonus.value = ''
      live_slots_bonus.value = ''
      coupon.value = ''
      account.value = ''
      name.value = ''
      bank_id.value = ''
      bank_num.value = ''
      phone_num.value = ''
      register_ts.value = ''
      unread_count.value = 0
    }

    async function logout() {
      clearInfo()
      clearToken()
      if (heartbeat) {
        clearInterval(heartbeat)
      }
      await router.replace('/')
      router.go(0)
    }

    function refreshToken() {
      if (tokenRefreshTime.value + 5 * 60 * 1000 < new Date().getTime()) {
        refreshTokenAPI().then((res) => {
          setToken(res.headers.get('authorization'))
          tokenRefreshTime.value = new Date().getTime()
        })
      }
    }

    function login(data) {
      return new Promise((resolve, reject) => {
        loginAPI(data)
          .then((res) => {
            setToken(res.headers.get('authorization'))
            tokenRefreshTime.value = new Date().getTime()
            updateInfo().then(() => {
              router.go(0)
              resolve()
            })
            if (heartbeat) {
              clearInterval(heartbeat)
            }
            userHeartbeat()
            heartbeat = setInterval(() => {
              userHeartbeat()
            }, 30000)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    function updateInfo() {
      getUnreadMessageCount().then(({ data }) => {
        unread_count.value = data.data.content
      })
      return getInfo().then(({ data }) => {
        const res = data.data.content
        id.value = res.id
        balance.value = res.balance
        cash.value = res.cash
        rebate.value = res.rebate
        sport_bonus.value = res.sport_bonus
        live_slots_bonus.value = res.live_slots_bonus
        coupon.value = res.coupon
        account.value = res.account
        name.value = res.name
        bank_id.value = res.bank_id
        bank_num.value = res.bank_num
        phone_num.value = res.phone_num
        register_ts.value = res.register_ts
      })
    }

    return {
      id,
      balance,
      cash,
      rebate,
      sport_bonus,
      live_slots_bonus,
      coupon,
      account,
      name,
      bank_id,
      bank_num,
      phone_num,
      register_ts,
      unread_count,
      heartbeat,
      tokenRefreshTime,
      login,
      logout,
      updateInfo,
      refreshToken,
    }
  },
  {
    persist: true,
  }
)
