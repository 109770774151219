import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useCommonStore = defineStore(
  'common',
  () => {
    const showLoginDialog = ref(false)
    const showAD = ref(true)
    const notifyType = ref('')
    const showNotify = ref(false)
    const notifyMessage = ref('')
    const showMenu = ref(false)
    let timeoutId = null

    const showNotifyMessage = (type, message) => {
      if (showNotify.value) {
        clearTimeout(timeoutId)
      }
      notifyType.value = type
      notifyMessage.value = message
      showNotify.value = true
      timeoutId = setTimeout(() => {
        showNotify.value = false
        timeoutId = null
      }, 2000)
    }
    const showErrorNotify = (message) => {
      showNotifyMessage('error', message)
    }
    const showSuccessNotify = (message) => {
      showNotifyMessage('success', message)
    }
    return {
      showLoginDialog,
      showAD,
      notifyType,
      showNotify,
      notifyMessage,
      showMenu,
      showNotifyMessage,
      showErrorNotify,
      showSuccessNotify,
    }
  },
  {}
)
